.soldHeader input {
  padding: 12px 8px;
}

.soldHeader label {
  font-size: 12px;
}

.soldHeaderSearch input::placeholder {
  font-size: 12px;
}

.paginationSearch input {
  padding: 5px 6px;
}

.paginationSearch input::placeholder {
  font-size: 12px;
}

.paginationSearch input::placeholder {
  font-size: 12px;
}

.paginationDropdown fieldset {
  padding: 7px 12px;
  /* margin-bottom: 12px; */
  /* margin-top: 12px; */
}

.paginationDropdown legend {
  font-size: 12px;
}

.paginationDropdown label {
  margin-right: 50px;
}

.soldHeaderSearch input {
  padding: 12px 8px;
}

.soldHeader .MuiInputLabel-shrink {
  font-size: 12px;
}

.quickPost {
  text-align: center;
}

.quickPost img {
  width: 100%;
  height: 80px;
  object-fit: contain;
  object-position: center;
  margin: 0 !important;
}

.photo-preview {
  position: relative;
  box-shadow: none;
}


.photo-preview img {
  width: 100%;
  border-radius: 12px;
  margin: 10px;
}

.deleteImgBtn {
  position: absolute;
  top: 10px;
  right: -10px;
  background: #fff;
  width: 30px;
  font-size: 20px;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #616f72;
}

.wrapper {
  /* bottom: 50%;
  right: 50%; */
  position: absolute;
}

.soldHeaderDropdown fieldset {
  padding: 7px 12px;
  /* margin-bottom: 12px; */
  /* margin-top: 12px; */
}

.soldHeaderDropdown legend {
  font-size: 12px;
}

.soldHeaderDropdown label {
  margin-right: 50px;
}