.category-filter-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category-filter-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  color: #000000;
}
.filter-main-category {
  margin-top: 35px;
}
.filter-title-category {
  margin-bottom: 18px !important;
}
.filter-content-category {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 13px;
}
.filter-data-button {
  padding-top: 16px;
  padding-bottom: 16px;
  background: #e9e9e9;
  border-radius: 30px;
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.filter-data-button:hover {
  border: 1px solid black;
}
.filter-option-name {
  margin-right: 16px;
}
.filter-data-selected {
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 30px;
}

.filter-data-selected svg {
  height: 13px;
}
.filter-dialog-product-search .MuiDialog-paper {
  border-radius: 15px !important;
}
.filter-dialog-product-search {
  margin-top: 0px;
  max-height: 100vh !important;
  z-index: 999999 !important;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 80% !important;
  width: 80% !important;
  padding: 0px 30px 20px 30px !important;
  overflow: hidden !important;
} */
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .category-filter-dialog-title {
    font-size: 15px;
    line-height: 22px;
  }
  .close-button-category-filter {
    width: 25px;
    height: 25px;
  }
  .category-filter-dialog-main-title {
    padding: 10px 15px !important;
  }
  .category-filter-dialog-main-content {
    padding: 0px 15px 10px 15px !important;
  }
  .filter-main-category {
    margin-top: 15px;
  }
  .filter-title-category {
    margin-bottom: 10px !important;
  }
  .filter-content-category {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 13px;
  }
  .filter-data-button {
    border-radius: 25px;
    font-size: 14px;
    line-height: 16px;
  }
  .filter-option-name {
    margin-right: 8px;
  }

  /* .css-ypiqx9-MuiDialogContent-root {
    padding: 20px 0px 10px 10px !important;
  } */

  /* .css-cyxlny-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
  } */
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .category-filter-dialog-title {
    font-size: 16px;
    line-height: 22px;
  }
  .category-filter-dialog-main-title {
    padding: 10px 15px !important;
  }
  .category-filter-dialog-main-content {
    padding: 0px 15px 10px 15px !important;
  }
  .close-button-category-filter {
    width: 25px;
    height: 25px;
  }
  .filter-content-category {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 13px;
  }
  .filter-data-button {
    border-radius: 25px;
    font-size: 14px;
    line-height: 16px;
  }
  .filter-option-name {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .category-filter-dialog-title {
    font-size: 18px;
    line-height: 22px;
  }
  .category-filter-dialog-main-title {
    padding: 10px 15px !important;
  }
  .category-filter-dialog-main-content {
    padding: 0px 15px 10px 15px !important;
  }
  .close-button-category-filter {
    width: 30px;
    height: 30px;
  }
  .filter-content-category {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 13px;
  }
  .filter-data-button {
    border-radius: 25px;
    font-size: 15px;
    line-height: 16px;
  }
  .filter-option-name {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .category-filter-dialog-title {
    font-size: 18px;
    line-height: 22px;
  }
  .close-button-category-filter {
    width: 35px;
    height: 35px;
  }
  .filter-content-category {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 13px;
  }
  .filter-data-button {
    border-radius: 25px;
    font-size: 15px;
    line-height: 18px;
  }
  .filter-option-name {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .filter-content-category {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 13px;
  }
  .filter-data-button {
    border-radius: 25px;
    font-size: 15px;
    line-height: 18px;
  }
  .filter-option-name {
    margin-right: 8px;
  }

  .close-button-category-filter {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (min-width: 1440px) {
  .close-button-category-filter {
    width: 40px;
    height: 40px;
  }
}
