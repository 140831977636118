.video-bazaar-div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 35px;
}

.video-bazaar-wid {
padding: 10px;
}



.video-measure{
    width:100%;
height: 358px;
}

.rh5v-Overlay_inner {
  background-color: rgba(255, 255, 255, 0.99) !important;
  border-radius: 50% !important;
}

.rh5v-Overlay_inner svg path {
  fill: rgba(0, 0, 0, 0.4);
}

.pagination-from-top{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .video-bazaar-div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .video-bazaar-div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.video-measure{
  width:100%;
height: 260px;
}
}


@media only screen and (min-width: 250px) and (max-width: 599px) {
  .video-bazaar-div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.video-measure{
  width:100%;
height: 200px;
}
.pagination-custom ul li button{
  font-size: 10px;
  height: 28px;
  min-width: 22px;
  max-width: 28px;
  
  }

}