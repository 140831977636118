.wishlist-dialog-div {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 28px;
}

.wishlist-dialog-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.wishlist-dialog-id {
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}
.wishlist-dialog-button {
  width: 100%;
  border: 0;
  margin-top: 15px;
  cursor: pointer;
  outline: none;
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 10px;
  padding: 20px;
}
.wishlist-dialog-button:hover {
  background-color: rgb(64, 64, 64);
  color: white;
}
.wishlist-dialog-text {
  margin-top: 15px;
}
.close-button-wishlist {
  background: #000000;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  border-radius: 50%;
}
.close-icon-wishlist {
  height: 15px;
  width: 15px;
}
.wishlist-dialog-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .wishlist-dialog-div {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 28px;
  }
}
