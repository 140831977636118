.testimonial-hero-image {
  width: 320px;
  height: 350px;
  margin-top: 30px;
}
.testimonial-section {
  margin-top: 40px;
}
/* New Approch For Unlimited Testimonials */
.testimonial-card-even {
  position: relative;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 80px;
}
.testimonial-card-odd {
  position: relative;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 80px;
}
.testimonial-profile-outside-even {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -67px;
  right: 20px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(199, 225, 219, 0.5);
}
.testimonial-profile-outside-odd {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -67px;
  left: 25px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(199, 225, 219, 0.5);
}
.testimonial-profile-inside-even {
  padding: 5px;
  border-radius: 50%;
}
.testimonial-profile-inside-odd {
  padding: 5px;
  border-radius: 50%;
}
.testimonial-profile-image-even {
  border-radius: 50%;
  width: 100%;
  /* height: 100%; */
  height: 120px;
}
.testimonial-profile-image-odd {
  border-radius: 50%;
  width: 100%;
  /* height: 100%; */
  height: 120px;
  overflow: hidden;
}
.testimonial-profile-illustrator-even {
  position: absolute;
  left: 10px;
  z-index: 0;
  top: -67px;
}
.testimonial-profile-illustrator-odd {
  position: absolute;
  right: 10px;
  z-index: 0;
  top: -67px;
}
.testimonial-profile-image-illu-even {
  width: 100px;
  height: 100px;
}
.testimonial-profile-image-illu-odd {
  width: 100px;
  height: 100px;
}
.testimonial-title-even div {
  text-align: right;
}
.testimonial-title-even {
  margin-right: 120px !important;
  text-transform: uppercase;
  color: #000000;
}
.testimonial-title-odd div {
  text-align: left;
}
.testimonial-title-odd {
  margin-left: 120px !important;
  text-transform: uppercase;
  color: #000000;
}
.testimonial-data-even {
  display: flex;
  margin-bottom: 20px !important;
  margin-top: 25px !important;
  text-align: left;
  align-items: flex-end;
}
.testimonial-data-odd {
  display: flex;
  margin-bottom: 20px !important;
  margin-top: 25px !important;
  text-align: left;
  align-items: center;
}
.testimonial-bywhom-even {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
}
.testimonial-bywhom-odd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
}
/* New Approch For Unlimited Testimonials */
.testimonial-story {
  display: flex;
  justify-content: center;
  position: relative;
}
.testimonial-story-main {
  position: relative;
  padding: 40px 65px 40px 35px;
}
.testimonial-story-content {
  background: #050505;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 70vw;
}
.testimonial-story-image {
  position: absolute;
  top: 25px;
  right: -50px;
  width: 140px;
  height: 140px;
}
.testimonial-story-main-title {
  margin-bottom: 13px !important;
  text-transform: uppercase;
}
.testimonial-traingle-button {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  right: 80px;
}
.testimonial-button-image {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.testimonial-story-main-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 60vw;
}
.testimonial-story-content-link {
  color: #005bfc;
  text-decoration: underline;
  cursor: pointer;
}
.testimonial-story-content-link:hover {
  color: #6284ff;
  cursor: pointer;
}
.testimonial-right-button {
  margin-bottom: 15px;
  margin-left: 5px;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .testimonial-hero-image {
    width: 230px;
    height: 230px;
  }
  .testimonial-card-odd {
    margin-bottom: 65px;
    padding: 45px 20px;
  }
  .testimonial-card-even {
    margin-bottom: 65px;
    padding: 45px 20px;
  }
  .testimonial-profile-outside-odd {
    width: 90px;
    height: 90px;
    top: -50px;
    left: 25px;
  }
  .testimonial-profile-outside-even {
    width: 90px;
    height: 90px;
    top: -50px;
    right: 25px;
  }
  .testimonial-profile-illustrator-odd {
    top: -40px;
  }
  .testimonial-profile-illustrator-even {
    top: -40px;
  }
  .testimonial-profile-image-illu-odd {
    width: 70px;
    height: 70px;
  }
  .testimonial-profile-image-illu-even {
    width: 70px;
    height: 70px;
  }
  .testimonial-title-odd {
    margin-left: 50px !important;
  }
  .testimonial-title-even {
    margin-right: 50px !important;
  }
  .testimonial-data-odd {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-data-even {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-story-main {
    padding: 35px 55px 35px 30px;
  }

  .testimonial-story-main-content {
    font-size: 12px;
    line-height: 20px;
  }
  .testimonial-story-content {
    width: 70vw;
  }
  .testimonial-story-image {
    top: 10px;
    right: -40px;
    width: 80px;
    height: 80px;
  }
  .testimonial-button-image {
    width: 20px;
    height: 20px;
  }
  .testimonial-story-main-content {
    width: 60vw;
  }
  .testimonial-traingle-button {
    bottom: 5px;
    right: 20px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .testimonial-hero-image {
    width: 250px;
    height: 250px;
  }
  .testimonial-card-odd {
    margin-bottom: 65px;
    padding: 45px 20px;
  }
  .testimonial-card-even {
    margin-bottom: 65px;
    padding: 45px 20px;
  }
  .testimonial-profile-outside-odd {
    width: 90px;
    height: 90px;
    top: -50px;
    left: 25px;
  }
  .testimonial-profile-outside-even {
    width: 90px;
    height: 90px;
    top: -50px;
    right: 25px;
  }
  .testimonial-profile-illustrator-odd {
    top: -40px;
  }
  .testimonial-profile-illustrator-even {
    top: -40px;
  }
  .testimonial-profile-image-illu-odd {
    width: 70px;
    height: 70px;
  }
  .testimonial-profile-image-illu-even {
    width: 70px;
    height: 70px;
  }
  .testimonial-title-odd {
    margin-left: 70px !important;
  }
  .testimonial-title-even {
    margin-right: 70px !important;
  }
  .testimonial-data-odd {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-data-even {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-story-main {
    padding: 35px 55px 35px 30px;
  }

  .testimonial-story-main-content {
    font-size: 12px;
    line-height: 20px;
  }
  .testimonial-story-content {
    width: 70vw;
  }
  .testimonial-story-image {
    top: -14px;
    right: -18px;
    width: 90px;
    height: 90px;
  }
  .testimonial-button-image {
    width: 20px;
    height: 20px;
  }
  .testimonial-story-main-content {
    width: 60vw;
  }
  .testimonial-traingle-button {
    bottom: 5px;
    right: 20px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .testimonial-hero-image {
    width: 270px;
    height: 270px;
  }
  .testimonial-card-odd {
    margin-bottom: 70px;
  }
  .testimonial-card-even {
    margin-bottom: 70px;
  }
  .testimonial-profile-outside-odd {
    width: 90px;
    height: 90px;
    top: -50px;
    left: 25px;
  }
  .testimonial-profile-outside-even {
    width: 90px;
    height: 90px;
    top: -50px;
    right: 25px;
  }
  .testimonial-profile-illustrator-odd {
    top: -55px;
  }
  .testimonial-profile-illustrator-even {
    top: -55px;
  }
  .testimonial-profile-image-illu-odd {
    width: 90px;
    height: 90px;
  }
  .testimonial-profile-image-illu-even {
    width: 90px;
    height: 90px;
  }
  .testimonial-title-odd {
    margin-left: 70px !important;
  }
  .testimonial-title-even {
    margin-right: 70px !important;
  }
  .testimonial-data-odd {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-data-even {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .testimonial-story-main {
    padding: 35px 55px 35px 30px;
  }
  .testimonial-story-main-content {
    font-size: 12px;
    line-height: 20px;
  }
  .testimonial-story-content {
    width: 70vw;
  }
  .testimonial-story-image {
    top: 80px;
    right: -60px;
    width: 100px;
    height: 100px;
  }
  .testimonial-button-image {
    width: 20px;
    height: 20px;
  }
  .testimonial-traingle-button {
    right: 50px;
  }
  .testimonial-story-main-content {
    width: 60vw;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .testimonial-hero-image {
    width: 290px;
    height: 300px;
  }
  .testimonial-story-main {
    padding: 35px 55px 35px 30px;
  }
  .testimonial-story-content {
    width: 70vw;
  }
  .testimonial-story-image {
    top: 35px;
    right: -40px;
    width: 120px;
    height: 120px;
  }
  .testimonial-button-image {
    width: 25px;
    height: 25px;
  }
  .testimonial-traingle-button {
    right: 60px;
  }
  .testimonial-story-main-content {
    width: 60vw;
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .testimonial-hero-image {
    width: 320px;
    height: 340px;
  }
  .testimonial-story-main {
    padding: 35px 55px 35px 30px;
  }
  .testimonial-story-content {
    width: 70vw;
  }
  .testimonial-story-image {
    top: 30px;
    right: -45px;
    width: 120px;
    height: 120px;
  }
  .testimonial-traingle-button {
    right: 60px;
  }
  .testimonial-button-image {
    width: 25px;
    height: 25px;
  }
  .testimonial-story-main-content {
    width: 60vw;
  }
}
