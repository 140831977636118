.mainpagetitle {
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
}
.mainsectiontitle {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
}
.sectiondata {
  font-family: "Poppins";
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
}
.contentData {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.sectionsubdata {
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
}
.wishlist-tablists {
  font-weight: 700 !important;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .mainpagetitle {
    font-size: 15px;
    line-height: 20px;
  }
  .mainsectiontitle {
    font-size: 13px;
    line-height: 18px;
  }
  .sectiondata {
    font-size: 12px;
    line-height: 16px;
  }
  .contentData {
    font-size: 12px;
    line-height: 16px;
  }
  .sectionsubdata {
    font-size: 10px;
    line-height: 12px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .mainpagetitle {
    font-size: 16px;
    line-height: 20px;
  }
  .mainsectiontitle {
    font-size: 14px;
    line-height: 18px;
  }
  .sectiondata {
    font-size: 12px;
    line-height: 16px;
  }
  .contentData {
    font-size: 12px;
    line-height: 16px;
  }
  .sectionsubdata {
    font-size: 10px;
    line-height: 12px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
}

@media only screen and (min-width: 1256px) and (max-width: 1439px) {
}
