.account-main-layout {
  margin-top: 112px;
  display: flex;
  flex-direction: column;
}

.account-layout-sidebar {
  width: 100%;
}

.main-title-account-page {
  display: grid;
}

.main-title-account-page>* {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}

.main-title-account-page>*:last-child {
  justify-self: right;
}

.wishlist-account-title {
  margin-bottom: 0;
}

.account-layout-button {
  background-color: transparent;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  padding: 0;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
  display: flex;
  line-height: 25px;
  color: #ffffff;
}

.account-layout-second-button {
  background-color: transparent;
  border: none;
  font-family: "Poppins";
  padding: 0;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  align-items: center;
  display: flex;
  line-height: 25px;
  color: black;
}

.account-layout-menu {
  background-color: black;
  padding: 14px;
}

.account-layout-second-menu {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 14px;
}

.account-layout-menu-text {
  margin-left: 22px;
}

.account-layout-children {
  width: 100%;
  margin-left: 62px;
}

.account-children-profile-content {
  margin-bottom: 10px;
  /* padding-top: 10px; */
}

.account-profile-content-title {
  margin-left: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #212121;
}

.account-profile-div {
  display: flex;
  justify-content: space-between;
}

.account-profile-content-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-profile-edit-div {
  width: 43px;
  border: none;
  cursor: pointer;
  height: 43px;
  background: #000000;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-profile-content-data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  margin-top: 19px;
  font-size: 18px;
  line-height: 25px;
  color: #212121;
}

.close-button-account-content {
  width: 43px;
  margin-left: 13px;
  border: none;
  height: 43px;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 7px;
}

.done-button-account-content {
  width: 43px;
  border: none;
  cursor: pointer;
  height: 43px;
  background: #000000;
  border-radius: 7px;
}

.account-input-content {
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 18px;
  padding: 14px 0 14px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  width: 40%;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.account-input-content-main {
  margin-top: 10px;
}

.account-input-content:focus {
  outline: none;
}

.password-button-selected {
  margin-bottom: 30px;
}

.wishlist-account-page {
  margin-top: 0;
}

.upgradeImageSearch {
  position: relative;
  padding: 12px 20px;
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 14px;
}

.not-found-data-account {
  margin-top: 30px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe6e6;
  border-radius: 14px;
}

.searchicon-upgrade-acccount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.search-icon-upgrade-account {
  width: 18px;
  height: 18px;
}

.search-input-account-upgrade {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  border: none;
  line-height: 20px;
  color: #000000;
}

.search-input-account-upgrade:focus {
  outline: none !important;
}

.upgrade-image-records {
  padding: 12px 40px 12px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 14px;
}

.radioicon-upgrade-acccount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownicon-upgrade-acccount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.radio-icon-upgrade-account {
  width: 20px;
  height: 20px;
}

.dropdown-icon-upgrade-account {
  width: 12px;
  height: 12px;
}

.records-text-upgrade-account {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-left: 20px;
  white-space: nowrap;
}

.upgrade-image-records-content {
  padding: 10px 15px 15px 10px;
  display: flex;
  right: 0;
  position: absolute;
  flex-direction: column;
  z-index: 1;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.records-text-content-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  border-radius: 14px;
  font-style: normal;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 18px;
  background: none;
  cursor: pointer;
  color: #000000;
  white-space: nowrap;
}

.records-text-content-buttons:hover {
  background-color: #000000;
  color: white;
}

.active-records-content-buttons {
  background-color: #000000;
  color: white;
}

.rotate-dropdown-icon-account {
  transform: rotate(180deg);
}

.last-upgrade-buttons {
  margin-bottom: 0px !important;
}

.not-found-data-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #cf2222;
}

.close-done-icon-account {
  width: 20px;
  height: 20px;
}

.account-menu-icons {
  width: 25px;
  height: 25px;
}

.main-div-upgrade-account {
  display: flex;
  align-items: center;
  gap: 20%;
  justify-content: space-between;
  width: 100%;
}

.select-account-pagelimit {
  background-color: white;
}

.upgrade-list-buttons {
  position: relative;
  width: 100%;
}

.account-box-layout {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 50px 40px;
}

.account-layout {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.account-edit-button {
  display: flex;
  justify-content: flex-end;
}

.account-order-table {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
  overflow-x: auto;
  margin-top: 25px;
}

.shadow-box {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
  overflow-x: auto;
  margin-top: 25px;
  background-color: #ffffff;
  padding-left: 25px;
}

.order-confirm-box {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px 40px 20px 40px;
  margin-top: 25px;
}

.order-action-icon {
  cursor: pointer;
  fill: black;
  width: 25px;
  height: 25px;
}

.left-right-arrow {
  fill: black;
  width: 20px;
  height: 20px;
}

.disabled-arrow {
  fill: #c8c8c8 !important;
}

.ArrowIconButton:hover .left-right-arrow {
  fill: white;
}

.order-action-iconButton {
  margin-left: 0 !important;
}

.order-action-iconButton:hover .order-action-icon {
  fill: white;
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .account-main-layout {
    margin-top: 60px;
  }

  .account-layout-sidebar-desktop {
    display: none;
  }

  .account-layout-children {
    margin-left: 0;
  }

  .account-layout-sidebar-mobile {
    display: flex;
    overflow-y: scroll;
    margin-bottom: 40px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .account-layout-sidebar-mobile::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .account-layout-sidebar-mobile {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .account-layout-menu {
    padding: 10px;
    margin-right: 15px;
  }

  .account-layout-second-menu {
    padding: 10px;
    margin-top: 0;
  }

  .profile-button-selected {
    margin: 0 15px 0 0;
  }

  .wishlist-button-selected {
    margin: 0 15px 0 0;
  }

  .upgrade-button-selected {
    margin: 0 15px 0 0;
  }

  .subscrition-button-selected {
    margin: 0 15px 0 0;
  }

  .password-button-selected {
    margin: 0 15px 0 0;
  }

  .account-layout-second-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-menu-text {
    white-space: nowrap;
  }

  .account-profile-content-title {
    font-size: 16px;
    line-height: 20px;
  }

  .account-profile-edit-div {
    width: 30px;
    height: 30px;
  }

  .account-profile-content-data {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .done-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-done-icon-account {
    width: 20px;
    height: 20px;
  }

  .account-input-content-main {
    margin-top: 10px;
  }

  .account-input-content {
    padding: 16px 0 16px 15px;
    font-size: 16px;
    line-height: 20px;
    width: 85%;
  }

  .upgradeImageSearch {
    padding: 10px 15px;
  }

  .not-found-data-account {
    padding: 10px 15px;
    margin-top: 15px;
  }

  .search-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .search-input-account-upgrade {
    font-size: 14px;
    line-height: 16px;
  }

  .upgrade-image-records {
    padding: 10px 15px;
  }

  .radio-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .dropdown-icon-upgrade-account {
    width: 12px;
    height: 12px;
  }

  .main-div-upgrade-account {
    flex-direction: column;
    gap: 20px;
  }

  .records-text-upgrade-account {
    font-size: 14px;
    line-height: 16px;
  }

  .upgrade-image-records-content {
    padding: 15px 8px;
  }

  .records-text-content-buttons {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .not-found-data-text {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .account-main-layout {
    margin-top: 60px;
  }

  .account-layout-sidebar-desktop {
    display: none;
  }

  .main-div-upgrade-account {
    flex-direction: column;
    gap: 20px;
  }

  .account-layout-children {
    margin-left: 0;
  }

  .account-layout-sidebar-mobile {
    display: flex;
    overflow-y: scroll;
    margin-bottom: 40px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .account-layout-sidebar-mobile::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .account-layout-sidebar-mobile {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .account-layout-menu {
    padding: 10px;
    margin-right: 15px;
  }

  .account-layout-second-menu {
    padding: 10px;
    margin-top: 0;
  }

  .profile-button-selected {
    margin: 0 15px 0 0;
  }

  .wishlist--buttonselected {
    margin: 0 15px 0 0;
  }

  .upgrade-button-selected {
    margin: 0 15px 0 0;
  }

  .subscrition-button-selected {
    margin: 0 15px 0 0;
  }

  .password-button-selected {
    margin: 0 15px 0 0;
  }

  .account-layout-second-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-menu-text {
    white-space: nowrap;
  }

  .account-profile-content-title {
    font-size: 16px;
    line-height: 20px;
  }

  .account-profile-edit-div {
    width: 30px;
    height: 30px;
  }

  .account-profile-content-data {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .done-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-done-icon-account {
    width: 20px;
    height: 20px;
  }

  .account-input-content-main {
    margin-top: 10px;
  }

  .account-input-content {
    padding: 16px 0 16px 15px;
    font-size: 14px;
    line-height: 18px;
    width: 80%;
  }

  .upgradeImageSearch {
    padding: 15px 20px;
  }

  .not-found-data-account {
    padding: 15px 20px;
    margin-top: 15px;
  }

  .search-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .search-input-account-upgrade {
    font-size: 14px;
    line-height: 16px;
  }

  .upgrade-image-records {
    padding: 15px 20px;
  }

  .radio-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .dropdown-icon-upgrade-account {
    width: 12px;
    height: 12px;
  }

  .records-text-upgrade-account {
    font-size: 14px;
    line-height: 16px;
  }

  .upgrade-image-records-content {
    padding: 5px;
  }

  .records-text-content-buttons {
    padding: 5px 15px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    border-radius: 10px;
  }

  .not-found-data-text {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .account-main-layout {
    margin-top: 60px;
  }

  .account-layout-sidebar-desktop {
    display: none;
  }

  .account-layout-children {
    margin-left: 0;
  }

  .main-div-upgrade-account {
    gap: 10px;
  }

  .account-layout-sidebar-mobile {
    display: flex;
    overflow-y: scroll;
    margin-bottom: 40px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .account-layout-sidebar-mobile::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .account-layout-sidebar-mobile {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .account-layout-menu {
    padding: 10px;
    margin-right: 15px;
  }

  .account-layout-second-menu {
    padding: 10px;
    margin-top: 0;
  }

  .profile-button-selected {
    margin: 0 15px 0 0;
  }

  .wishlist-button-selected {
    margin: 0 15px 0 0;
  }

  .upgrade-button-selected {
    margin: 0 15px 0 0;
  }

  .subscrition-button-selected {
    margin: 0 15px 0 0;
  }

  .password-button-selected {
    margin: 0 15px 0 0;
  }

  .account-layout-second-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-button {
    font-size: 14px;
    line-height: 16px;
  }

  .account-layout-menu-text {
    white-space: nowrap;
  }

  .account-profile-content-title {
    font-size: 16px;
    line-height: 20px;
  }

  .account-profile-edit-div {
    width: 30px;
    height: 30px;
  }

  .account-profile-content-data {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .done-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-done-icon-account {
    width: 20px;
    height: 20px;
  }

  .account-input-content-main {
    margin-top: 10px;
  }

  .account-input-content {
    padding: 16px 0 16px 15px;
    font-size: 14px;
    line-height: 18px;
    width: 80%;
  }

  .upgradeImageSearch {
    padding: 12px 15px;
  }

  .not-found-data-account {
    padding: 20px 30px;
    margin-top: 20px;
  }

  .search-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .search-input-account-upgrade {
    font-size: 16px;
    line-height: 18px;
  }

  .upgrade-image-records {
    padding: 12px 15px;
  }

  .radio-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .dropdown-icon-upgrade-account {
    width: 12px;
    height: 12px;
  }

  .records-text-upgrade-account {
    font-size: 16px;
    line-height: 18px;
  }

  .upgrade-image-records-content {
    padding: 5px 10px;
  }

  .records-text-content-buttons {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    border-radius: 14px;
  }

  .not-found-data-text {
    font-size: 16px;
    line-height: 18px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1350px) {
  .account-layout-sidebar-mobile {
    display: none;
  }

  .account-layout-sidebar {
    width: 40%;
  }

  .account-main-layout {
    flex-direction: row;
  }

  .account-layout-menu {
    padding: 12px;
  }

  .account-layout-button {
    font-size: 16px;
    line-height: 20px;
  }

  .account-layout-second-menu {
    padding: 12px;
    margin-top: 20px;
  }

  .password-button-selected {
    margin-bottom: 20px;
  }

  .account-layout-second-button {
    font-size: 16px;
    line-height: 20px;
  }

  .account-layout-children {
    margin-left: 50px;
  }

  .account-profile-content-title {
    font-size: 16px;
    line-height: 20px;
  }

  .account-profile-edit-div {
    width: 30px;
    height: 30px;
  }

  .account-profile-content-data {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .done-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-button-account-content {
    width: 30px;
    height: 30px;
  }

  .close-done-icon-account {
    width: 20px;
    height: 20px;
  }

  .account-input-content-main {
    margin-top: 10px;
  }

  .account-input-content {
    padding: 16px 0 16px 15px;
    font-size: 14px;
    line-height: 18px;
  }

  .upgradeImageSearch {
    padding: 12px 15px;
  }

  .not-found-data-account {
    padding: 20px 30px;
    margin-top: 20px;
  }

  .search-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .search-input-account-upgrade {
    font-size: 16px;
    line-height: 18px;
  }

  .upgrade-image-records {
    padding: 12px 15px;
  }

  .radio-icon-upgrade-account {
    width: 20px;
    height: 20px;
  }

  .dropdown-icon-upgrade-account {
    width: 12px;
    height: 12px;
  }

  .records-text-upgrade-account {
    font-size: 16px;
    line-height: 18px;
  }

  .upgrade-image-records-content {
    padding: 5px 10px;
  }

  .records-text-content-buttons {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
    border-radius: 14px;
  }

  .not-found-data-text {
    font-size: 16px;
    line-height: 18px;
  }

  .main-div-upgrade-account {
    gap: 10px;
  }
}

@media only screen and (min-width: 1351px) {
  .account-layout-sidebar-mobile {
    display: none;
  }

  .account-main-layout {
    flex-direction: row;
  }

  .account-layout-sidebar {
    width: 40%;
  }
}