.technical-horiz-main-table {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.technical-introduction {
  border-radius: 20px;
  padding: 20px 150px !important;
  text-align: center;
  margin-top: 25px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.technical-horizontal-images {
  margin-top: 25px;
}
.technical-horizontal-title {
  margin-bottom: 16px !important;
}
.table-h-technical {
  box-shadow: 0px 10px 20px rgba(156, 157, 160, 0.5);
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.table-row-h-technical {
  background: #000000;
}
.table-row-h-technical td {
  padding: 14px 25px;
}
table {
  border-spacing: 0;
}
.table-row-body-h-technical td {
  white-space: nowrap;
  padding: 20px 25px;
}
.table-row-body-download-technical {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.no-highlighted-color {
  color: #000000 !important;
  cursor: auto !important;
}
.table-row-body-download-technical td {
  white-space: nowrap;
  padding: 33px 19px;
}
.table-row-body-download-technical td:last-child {
  cursor: pointer;
}
.highlighted-color-technical td:last-child {
  color: #005bfc;
  cursor: pointer;
}
.border-bottom-table td {
  border-bottom: 1px solid #b7b7b7;
}
.table-vertical-technical td {
  padding: 30px 62px;
}
.table-vertical-text-technical {
  margin-bottom: 12px !important;
}
.table-vertical-bluetext-technical {
  color: #005bfc;
  font-weight: 700;
  cursor: pointer;
}
.technical-downloadtime-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 7px;
  color: #000000;
}
.technical-downloadtime-subtitle {
  font-family: "Poppins";
  text-align: center;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.technical-hero-image {
  width: 300px;
  height: 300px;
  margin-top: 20px;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .technical-introduction {
    padding: 10px 20px !important;
    margin-top: 20px !important;
  }

  .technical-hero-image {
    width: 200px;
    height: 200px;
  }
  .technical-horiz-main-table {
    overflow-x: scroll;
  }
  .table-row-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-download-technical {
    font-size: 14px;
    line-height: 16px;
  }
  .table-row-body-download-technical td {
    padding: 15px 10px;
  }
  .table-vertical-technical td {
    padding: 15px 10px;
  }
  .technical-downloadtime-title {
    font-size: 14px;
    line-height: 16px;
  }
  .technical-downloadtime-subtitle {
    font-size: 14px;
    line-height: 16px;
  }
  .technical-horizontal-images {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .technical-introduction {
    padding: 10px 20px !important;
    margin-top: 20px !important;
  }
  .technical-hero-image {
    width: 220px;
    height: 220px;
  }
  .technical-horiz-main-table {
    overflow-x: scroll;
  }
  .table-row-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-download-technical {
    font-size: 16px;
    line-height: 18px;
  }
  .table-row-body-download-technical td {
    padding: 15px 10px;
  }
  .table-vertical-technical td {
    padding: 15px 10px;
  }
  .technical-downloadtime-title {
    font-size: 14px;
    line-height: 16px;
  }
  .technical-downloadtime-subtitle {
    font-size: 14px;
    line-height: 16px;
  }
  .technical-horizontal-images {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .technical-introduction {
    padding: 20px 30px !important;
  }
  .technical-hero-image {
    width: 250px;
    height: 250px;
  }
  .technical-horiz-main-table {
    overflow-x: scroll;
  }
  .table-row-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-h-technical td {
    padding: 15px 10px;
  }
  .table-row-body-download-technical {
    font-size: 16px;
    line-height: 18px;
  }
  .table-row-body-download-technical td {
    padding: 15px 10px;
  }
  .table-vertical-technical td {
    padding: 15px 10px;
  }
  .technical-downloadtime-title {
    font-size: 16px;
    line-height: 18px;
  }
  .technical-downloadtime-subtitle {
    font-size: 16px;
    line-height: 18px;
  }
  .technical-horizontal-images {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1350px) {
  .technical-introduction {
    padding: 20px 30px !important;
  }
  .technical-horiz-main-table {
    overflow-x: scroll;
  }
  .table-row-h-technical td {
    padding: 20px 15px;
  }
  .technical-hero-image {
    width: 275px;
    height: 275px;
  }
  .table-row-body-h-technical td {
    padding: 15px 15px;
  }
  .table-row-body-download-technical {
    font-size: 16px;
    line-height: 18px;
  }
  .table-row-body-download-technical td {
    padding: 20px 15px;
  }
  .table-vertical-technical td {
    padding: 20px 15px;
  }
  .technical-downloadtime-title {
    font-size: 16px;
    line-height: 18px;
  }
  .technical-downloadtime-subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}
