.explore-more {
  background-color: black;
  border: 0;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  letter-spacing: 0.6px;
  justify-content: center;

  
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.filter-icon {
  fill: white;
}
.explore-more:hover {
  background-color: white;
  border: 2px solid black;
  color: black;
}
.explore-more:hover .filter-icon {
  fill: black;
}
.explore-more:hover .searchresult-filter-icon {
  fill: black;
}
.main-container-aboutus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading-with-button {
  display: grid;
  align-items: center;
}
.heading-with-button > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}
.heading-with-button > *:last-child {
  justify-self: right;
}
.heading-with-button-content {
  display: grid;
  align-items: center;
}
.heading-with-button-content > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}
.heading-with-button-content > *:last-child {
  justify-self: right;
}
/* .main-container-aboutus {
  display: grid;
  align-items: center;
}
.main-container-aboutus > * {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}
.main-container-aboutus > *:last-child {
  justify-self: right;
} */
.container-searchresult-title {
  display: grid;
  align-items: center;
}
.moreinfo-image-class {
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreinfo-viewall-button {
  background: none;
  border: 2px solid black;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
}
.moreinfo-viewall-button:hover {
  background: rgb(237, 237, 237);
}
.about-info {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 40px;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}
.helpTopicsSection-data {
  /* background-color: #ffffff; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 40px;
  flex-direction: column;
  display: flex;
  margin-top: 25px !important;
}
.helptopics-faq {
  align-items: flex-start;
}
.accordion-group {
  width: 100%;
}
.accordion-item {
  background-color: #f0f4fd;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.contactus-acc-item {
  background-color: #f0f4fd;
  margin-bottom: 10px;
  border-radius: 10px;
}
.arrow-icon-faq {
  fill: black !important;
}
.accordion-questionmark {
  margin-right: 10px;
}
.accordion-title {
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
}
.accordion-content {
  padding: 10px 15px;
  margin-left: 15px;
}
.first-content-about-us {
  width: 80%;
  text-align: left;
}
.second-content-about-us {
  text-align: justify;
  margin-bottom: 25px;
}

.about-us-border {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.about-content-title {
  margin-bottom: 10px !important;
}
.align-content-left {
  text-align: left;
}
.about-content-data-last {
  margin-top: 15px;
}
.more-info-about {
  margin-top: 29px;
}
.last-content-about-us {
  margin-bottom: 0;
}
.info-menu-about {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-info-card {
  cursor: pointer;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.text-info-card {
  margin-top: 20px !important;
  color: #000000;
}
.close-button-about-us {
  width: 40px;
  height: 40px;
}
.about-dialog-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-dialog-content {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 28px;
}
.about-dialog-card {
  cursor: pointer;
  background: #ffffff;
  border: 2px solid #c9c9c9;
  border-radius: 15px;
  padding-top: 25px;
  padding-bottom: 40px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-dialog-product-search .MuiDialog-paper {
  border-radius: 15px !important;
}
.delete-confirmation-dialog .MuiDialog-paper {
  border-radius: 15px !important;
}
.icon-box-about-dialog {
  padding: 6px;
  background: #ffffff;
  border-radius: 50%;
}
.about-us-hero-image {
  margin-top: 30px;
  width: 400px;
  height: 300px;
}
.addtocart-hero-image {
  width: 300px;
  height: 300px;
}
.icon-about-dialog {
  width: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.icon-svg-main-info {
  width: 30px;
  height: 30px;
}
.price-icon-about {
  background: #ff3e3e;
}
.price-box-about {
  box-shadow: 0px 10px 20px #ffc6c6;
}
.search-icon-about {
  background: #3e68ff;
}
.search-box-about {
  box-shadow: 0px 10px 20px #d9e1ff;
}
.order-icon-about {
  background: #0f2576;
}
.order-box-about {
  box-shadow: 0px 10px 20px #d9e1ff;
}
.download-icon-about {
  background: #e1a816;
}
.download-box-about {
  box-shadow: 0px 10px 20px #ffeab6;
}
.license-icon-about {
  background: #67cb2a;
}
.license-box-about {
  box-shadow: 0px 10px 20px #d1fdb6;
}
.wishlist-icon-about {
  background: #ff3ebd;
}
.wishlist-box-about {
  box-shadow: 0px 10px 20px #ffc0ea;
}
.account-icon-about {
  background: #9a13fc;
}
.account-box-about {
  box-shadow: 0px 10px 20px #e2bbff;
}
.contact-icon-about {
  background: #ffa63e;
}
.contact-box-about {
  box-shadow: 0px 10px 20px #ffdfba;
}
.glossary-icon-about {
  background: #178d6a;
}
.glossary-box-about {
  box-shadow: 0px 10px 20px #beffec;
}
.about-dialog-card-title {
  font-family: "Poppins";
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
}
/* .css-cyxlny-MuiPaper-root-MuiDialog-paper {
  border-radius: 15px !important;
  margin: 0 !important;
} */

.about-info-image {
  width: 50px;
  height: 50px;
}

.first-info-card {
  margin-right: 45px;
}
.aboutus-dialog-product-search {
  margin-top: 0px;
  max-height: 100vh !important;
  z-index: 999999 !important;
}
.confirmation-dialog-image {
  width: 100%;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  max-width: 80% !important;
  padding: 0px 30px 20px 30px !important;
  overflow: hidden !important;
} */

/* .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
  overflow-y: scroll !important;
}

.MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar {
  width: 10px !important;
  background: white !important;
} */

/* Optional: show position indicator in red */
/* .MuiDialogTitle-root
  + .css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar-thumb {
  background: white !important;
}

.MuiDialogTitle-root
  + .css-ypiqx9-MuiDialogContent-root:hover::-webkit-scrollbar-thumb {
  background: #cccccc !important;
  border-radius: 7px;
} */

.email-wishlists-button {
  height: 65px;
  width: 100%;
  border-radius: 10px;
  background-color: black;
  color: white;
  margin-top: 5px;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.5px;
  outline: none;
  border: 0;
}
.close-about-us-dialog-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.search-tips-points {
  text-align: start;
}
.block-bank-details {
  width: 70%;
}
.list-bank-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.line-height-content {
  line-height: 25px !important;
  text-align: justify;
}
.paragraph-divider-space {
  margin-top: 10px;
}
.content-bank-details {
  /* margin-left: 30px; */
}
.lists-steps-ordering {
  margin-bottom: 5px;
  text-align: justify;
}
.text-align-right {
  text-align: right !important;
}
.left-side-margin {
  margin-left: 25px;
}
.steps-count-ordering {
  font-weight: 700;
}
.steps-ordering {
  list-style: none;
  padding: 0;
}
.searchresult-filter-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchresult-filter-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  margin-right: 5px;
}
.helptopics-layout {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* align-items: center; */
}

.sidebar-helptopics {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  /* overflow-x: scroll; */
}
.sidebar-accountLayout {
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  /* overflow-x: scroll; */
}
.customsidebar-menu-buttons {
  margin-bottom: 5px !important;
}
.content-helptopics {
  width: 100%;
  padding-left: 20px;
}
.accountLayout-childen {
  width: 75%;
  padding-left: 50px;
  padding-right: 10px;
  margin-top: 30px;
}
.helptopics-sidebar-menu {
  width: 25px;
  margin-right: 15px;
  height: 25px;
}
.sidebarLayoutButtonStyle:hover .helptopics-sidebar-menu {
  fill: white;
}
.pricingBankDetails {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.heading-button-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.heading-button-title-listing {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.heading-button-title-listing-mobile {
  display: none;
}
.heading-button-title-img {
  width: 100px;
  height: 20px;
}
.remove-filter-icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}
.heading-button-listing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.heading-buttons-listing {
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .helptopics-layout {
    flex-direction: column;
    margin-top: 10px;
  }
  .account-helptopics-layout {
    margin-top: 20px;
  }
  .sidebar-helptopics {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* margin-bottom: 20px; */
    margin-top: 0;
    overflow: scroll;
  }
  .sidebar-accountLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* margin-bottom: 20px; */
    margin-top: 0;
    overflow: scroll;
  }
  .heading-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .heading-with-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  .heading-title-text {
    font-size: 14px !important;
  }
  .searchresult-filter-icon {
    width: 12px;
    height: 12px;
  }
  .accordion-item {
    margin-left: 0;
    margin-right: 0;
  }
  .customsidebar-menu-buttons {
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }
  .content-helptopics {
    padding-left: 0;
  }
  .accountLayout-childen {
    padding-left: 0;
    width: 100%;
  }
  .helptopics-sidebar-menu {
    width: 20px;
    margin-right: 10px;
    height: 20px;
  }
  .pricingBankDetails {
    width: 100%;
  }
  .explore-more {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 11px;
    height: 35px;
  }
  .container-searchresult-title {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .searchresult-title {
    grid-column: span 3 / span 3;
  }
  .first-content-about-us {
    width: 90%;
  }
  .about-info {
    margin-top: 20px !important;
    padding-top: 20px;
    width: auto;
    padding-bottom: 20px;
  }
  .about-us-border {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .list-bank-details {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .block-bank-details {
    width: 100%;
    margin-left: 0 !important;
  }
  .second-content-about-us {
    margin-bottom: 15px;
  }
  .about-info-card {
    width: 110px;
    height: 110px;
  }
  .text-info-card {
    margin-top: 14px !important;
  }
  .about-info-image {
    width: 35px;
    height: 35px;
  }
  .first-info-card {
    margin-right: 20px;
  }
  .about-content-data-last {
    margin-top: 10px;
  }
  .about-dialog-content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }
  .close-button-about-us {
    width: 35px;
    height: 35px;
  }
  .about-dialog-card {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .icon-about-dialog {
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .icon-svg-main-info {
    width: 22px;
    height: 22px;
  }
  .about-dialog-card-title {
    font-size: 14px;
    line-height: 17px;
  }
  .about-dialog-svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-us-hero-image {
    width: 300px;
    height: 200px;
  }
  .addtocart-hero-image {
    width: 250px;
    height: 250px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .heading-button-title-listing {
    display: none;
  }
  .heading-button-title-listing-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .helptopics-layout {
    flex-direction: column;
    margin-top: 10px;
  }
  .account-helptopics-layout {
    margin-top: 20px;
  }
  .heading-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .heading-with-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  .heading-title-text {
    font-size: 14px !important;
  }
  .sidebar-helptopics {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin-top: 0;
    padding: 5px;
    /* margin-bottom: 20px; */
  }
  .sidebar-accountLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin-top: 0;
    padding: 5px;
    /* margin-bottom: 20px; */
  }
  .customsidebar-menu-buttons {
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }
  .content-helptopics {
    padding-left: 0;
  }
  .accountLayout-childen {
    padding-left: 0;
    width: 100%;
  }
  .helptopics-sidebar-menu {
    width: 20px;
    margin-right: 10px;
    height: 20px;
  }
  .accordion-item {
    margin-left: 0;
    margin-right: 0;
  }
  .pricingBankDetails {
    width: 100%;
  }
  .explore-more {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    height: 40px;
  }
  .container-searchresult-title {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .container-searchresult-title > *:last-child {
    width: 85%;
  }
  .searchresult-title {
    grid-column: span 3 / span 3;
  }
  .filter-icon {
    height: 15px !important;
  }
  .first-content-about-us {
    width: 90%;
  }
  .list-bank-details {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .block-bank-details {
    width: 100%;
    margin-left: 0 !important;
  }
  .about-info {
    width: auto;
    margin-top: 30px !important;
  }
  .about-us-border {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .second-content-about-us {
    margin-bottom: 20px;
  }
  .about-info-card {
    width: 110px;
    height: 110px;
  }
  .about-info-image {
    width: 40px;
    height: 40px;
  }
  .first-info-card {
    margin-right: 30px;
  }
  .about-dialog-content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 8px;
  }
  .close-button-about-us {
    width: 35px;
    height: 35px;
  }
  .icon-about-dialog {
    width: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
  }
  .icon-svg-main-info {
    width: 20px;
    height: 20px;
  }
  .about-dialog-card-title {
    font-size: 14px;
    line-height: 16px;
    margin-top: 20px;
  }
  .about-dialog-card {
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .about-dialog-svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-us-hero-image {
    width: 350px;
    height: 250px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .addtocart-hero-image {
    width: 250px;
    height: 250px;
  }
  .heading-button-title-listing {
    display: none;
  }
  .heading-button-title-listing-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .helptopics-layout {
    flex-direction: column;
  }
  .account-helptopics-layout {
    margin-top: 20px;
  }
  .heading-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
  }
  .heading-with-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .heading-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  .heading-title-text {
    font-size: 16px !important;
  }
  .sidebar-helptopics {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin-top: 0;
    padding: 5px;
    /* margin-bottom: 20px; */
  }
  .sidebar-accountLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin-top: 0;
    padding: 5px;
    /* margin-bottom: 20px; */
  }
  .customsidebar-menu-buttons {
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }
  .content-helptopics {
    padding-left: 0;
  }
  .accountLayout-childen {
    padding-left: 0;
    width: 100%;
  }
  .pricingBankDetails {
    width: 100%;
  }
  .helptopics-sidebar-menu {
    width: 20px;
    margin-right: 10px;
    height: 20px;
  }
  .close-button-about-us {
    width: 35px;
    height: 35px;
  }
  .first-content-about-us {
    width: 80%;
  }

  .about-us-border {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .block-bank-details {
    width: 80%;
  }
  .second-content-about-us {
    margin-bottom: 20px;
  }
  .about-info {
    width: auto;
    margin-top: 40px !important;
  }
  .about-info-card {
    width: 130px;
    height: 130px;
  }

  .about-info-image {
    width: 40px;
    height: 40px;
  }
  .first-info-card {
    margin-right: 30px;
  }
  .about-dialog-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
  }
  .explore-more {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;
  }
  .icon-about-dialog {
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .about-dialog-card-title {
    font-size: 14px;
    line-height: 16px;
  }
  .about-dialog-svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-svg-main-info {
    width: 28px;
    height: 28px;
  }
  .about-us-hero-image {
    width: 350px;
    height: 250px;
  }
  .addtocart-hero-image {
    width: 250px;
    height: 250px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .about-dialog-card {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .container-searchresult-title > * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }
  .container-searchresult-title > *:last-child {
    justify-self: right;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .first-content-about-us {
    width: 80%;
  }
  .about-info {
    width: auto;
  }
  .heading-button-title {
    max-width: 70%;
  }
  /* .aboutus-dialog-product-search {
    margin: 90px 10px 20px 10px !important;
  } */
  .about-us-border {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .second-content-about-us {
    margin-bottom: 20px;
  }
  .block-bank-details {
    width: 80%;
  }
  .about-dialog-card-title {
    font-size: 16px;
    line-height: 22px;
  }
  .about-info-card {
    width: 150px;
    height: 150px;
  }
  .about-info-image {
    width: 45px;
    height: 45px;
  }
  .first-info-card {
    margin-right: 40px;
  }
  .about-dialog-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 28px;
  }
  .close-button-about-us {
    width: 35px;
    height: 35px;
  }
  .about-us-hero-image {
    width: 350px;
    height: 250px;
  }
  .explore-more {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .about-dialog-card {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .container-searchresult-title > * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }
  .container-searchresult-title > *:last-child {
    justify-self: right;
  }
}
@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .about-dialog-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 28px;
  }
  .heading-button-title {
    max-width: 70%;
  }
  .about-info-card {
    width: 170px;
    height: 170px;
  }
  .about-info {
    width: 80%;
  }
  .about-us-hero-image {
    width: 350px;
    height: 250px;
  }
  .explore-more {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .about-dialog-card {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .container-searchresult-title > * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }
  .container-searchresult-title > *:last-child {
    justify-self: right;
  }
}
@media only screen and (min-width: 1440px) {
  .about-info-card {
    width: 180px;
    height: 180px;
  }
  .heading-button-title {
    max-width: 70%;
  }
  .about-dialog-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 28px;
  }
  .last-content-about-us {
    margin-bottom: 0;
  }
  .container-searchresult-title > * {
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }
  .container-searchresult-title > *:last-child {
    justify-self: right;
  }
}
