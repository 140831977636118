.soldHeader input {
    padding: 12px 8px;
}
.soldHeader label{
    font-size: 12px;
}
.soldHeaderSearch input::placeholder{
    font-size: 12px;
}
.soldHeaderSearch input {
  padding: 12px 8px;
}
.soldHeader .MuiInputLabel-shrink {
  font-size: 12px;
} 
.soldHeaderDropdown fieldset {
   padding: 7px 12px;
   /* margin-bottom: 12px; */
   /* margin-top: 12px; */
}
.soldHeaderDropdown legend {
    font-size: 12px;
 }
 .soldHeaderDropdown label {
    margin-right: 50px;
 }