.home-image-list-div {
  position: relative;
}

.ReactModal__Overlay {
  z-index: 99999999 !important;
}

.ReactModal__Overlay--before-close {
  pointer-events: none !important;
}

.home-image-list-image {
  width: 100%;
}

.home-image-list-text {
  display: none;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.know-more-why-image:hover {
  color: #005bfc;
}
.search-button:hover {
  background-color: #8b8b8b;
  color: white;
}
.home-image-list-div:hover .home-image-list-text {
  display: block;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  font-weight: 700;
  color: white;
  animation: fade-in 1s;
  letter-spacing: 1.7px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  margin-top: 0px;
  /* margin-bottom: 3px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.more-info-halfcircleh2 {
  display: none;
}
.div-main-like:hover .more-info-halfcircleh2 {
  height: 150px;
  width: 280px;
  background: rgb(0, 0, 0, 0.6);
  position: absolute;
  animation: fade-in 1s;
  bottom: 1px;
  left: 0;
  cursor: pointer;
  right: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 10px 20px 20px;
}

.div-other-like:hover .more-info-halfcircleh2 {
  height: 150px;
  width: 280px;
  background: rgb(0, 0, 0, 0.6);
  position: absolute;
  animation: fade-in 1s;
  bottom: 1px;
  left: 0;
  cursor: pointer;
  right: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 10px 20px 20px;
}
.more-info-gridh2 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.home-like-firstimage {
  object-fit: cover;
}
.home-like-image {
  /* height: 300px; */
  object-fit: cover;
}
.more-first-steph2 {
  margin-top: 85px;
  height: 25px;
}

.more-first-steph2:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.more-second-1-steph2 {
  margin-top: 39px;
  height: 25px;
  margin-left: -9px;
}
.more-second-1-steph2:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}
.more-second-2-steph2 {
  margin-top: 39px;
  height: 25px;
  margin-left: 10px;
}
.more-second-2-steph2:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}
.more-third-1-steph2 {
  margin-top: 1px;
  height: 29px;
  margin-left: -3px;
}
.more-third-1-steph2:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}
.more-third-2-steph2 {
  margin-top: 1px;
  height: 29px;
  margin-left: 3px;
}
.more-third-2-steph2:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}
.more-info-halfcircleh2 p {
  color: #ffffff;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  margin-top: -27px;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.5px !important;
}
.home-image-zoomin {
  display: none;
}
.div-main-like:hover .home-image-zoomin {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 17px;
  background: rgb(0, 0, 0, 0.6);
  padding: 9px;
  border-radius: 50%;
  height: 30px;
  animation: fade-in 1s;
  display: block;
}
.div-other-like:hover .home-image-zoomin {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 17px;
  background: rgb(0, 0, 0, 0.6);
  padding: 9px;
  border-radius: 50%;
  height: 30px;
  animation: fade-in 1s;
  display: block;
}
.image-liked-section {
  margin-top: 25px;
}
.mainImageGrid {
  display: grid;
  margin-top: 33px;
  gap: 10px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.div-main-like {
  grid-row: span 2 / span 2;
  grid-column: span 2 / span 2;
  position: relative;
}
.div-other-like {
  position: relative;
  /* height: 300px; */
}
.imageClass {
  object-fit: contain;
}
.mainImagesPopular {
  gap: 10px;
}
.mainImagesPopular img {
  width: 100%;
  height: auto;
  margin: 2px auto;
  cursor: pointer;
}
.why-image-bazaar-section {
  background-color: #050505;
  width: 100%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  margin-top: 70px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  color: white;
}
.title-why-section {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.paragraph-why-section {
  margin-top: 26px !important;
  line-height: 24px !important;
  display: flex;
  text-align: justify;
  align-items: flex-end;
}
.paragraph-why-section a {
  color: white;
  font-weight: 700;
}
.why-images {
  position: absolute;
  top: -32px;
  right: -60px;
}

.popular-search-section {
  position: relative;
  margin-top: 40px;
}

.search-buttons-group {
  display: grid;
  margin-top: 38px;
}
.search-button {
  background: #f6faff;
  border: 3px solid #8b8b8b;
  border-radius: 30px;
  height: 50px;
  font-family: "Poppins";
  z-index: 0;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
}
.popular-search-image {
  position: absolute;
  top: -20px;
  left: -70px;
}

.home-signin {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .main-display-grid {
  display: flex;
  flex-direction: row;
} */
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .mainImageGrid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .home-like-image {
    height: 100%;
  }
  .div-other-like {
    height: auto;
  }
  .popular-search-image {
    width: 100px;
    height: 100px;
    top: 34px;
    left: -31px;
  }
  .why-images {
    width: 65px;
    height: 200px;
    top: -27px;
    right: -15px;
  }
  .why-image-bazaar-section {
    width: 100%;
    margin-top: 30px;
  }
  .image-liked-section {
    margin-top: 15px;
  }
  .div-main-like {
    grid-row: 1;
    grid-column: 1;
  }
  .mainImagesPopular {
    column-count: 1;
  }
  .search-buttons-group {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 8px;
  }
  .search-button {
    border: 1px solid #8b8b8b;
  }
  .main-why-section {
    padding-left: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 15px;
  }
  .imagelist-adjust-mob {
    column-count: 1 !important;
  }
  .category-mob-fix {
    column-count: 1 !important;
  }
  .div-other-like:hover .more-info-halfcircleh2 {
    height: 100px;
    width: 200px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    padding: 10px;
  }
  .more-first-steph2 {
    height: 20px;
    width: 20px;
    margin-top: 60px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .mainImagesPopular {
    column-count: 2;
  }
  .home-like-image {
    height: 100%;
  }
  .div-other-like {
    height: auto;
  }
  .imagelist-adjust-mob {
    column-count: 2 !important;
  }

  .category-mob-fix {
    column-count: 1 !important;
  }

  .image-popular-section {
    margin-top: 0;
  }

  .mainImageGrid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .div-main-like {
    grid-row: 1;
    grid-column: 1;
  }

  .image-liked-section {
    margin-top: 15px;
  }
  .search-buttons-group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px 10px;
  }
  .popular-search-image {
    width: 100px;
    height: 100px;
    top: 34px;
    left: -31px;
  }
  .why-images {
    width: 75px;
    height: 230px;
    top: -27px;
    right: -10px;
  }
  .main-why-section {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
  }
  .why-image-bazaar-section {
    width: 100%;
    margin-top: 30px;
  }
  .paragraph-why-section {
    margin-top: 15px !important;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .mainImagesPopular {
    column-count: 3;
  }
  .imagelist-adjust-mob {
    column-count: 2 !important;
  }
  .div-main-like {
    grid-row: span 1 / span 1;
    grid-column: span 4 / span 4;
  }
  .div-other-like {
    grid-row: span 1 / span 1;
    grid-column: span 2 / span 2;
  }
  .popular-search-image {
    width: 180px;
    height: 180px;
    top: 34px;
    left: -56px;
  }
  .main-why-section {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
  }
  .why-images {
    width: 100px;
    height: 300px;
    top: -27px;
    right: -56px;
  }
  .search-buttons-group {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 25px 15px;
    /* padding-left: 44px;
    padding-right: 44px; */
  }
  .category-mob-fix {
    column-count: 2 !important;
  }
  .search-button {
    border: 3px solid #8b8b8b;
  }
  .paragraph-why-section {
    margin-top: 15px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .category-mob-fix {
    column-count: 3 !important;
  }
  .mainImagesPopular {
    column-count: 3;
  }
  .mainImageGrid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .div-main-like {
    grid-row: span 1 / span 1;
    grid-column: span 4 / span 4;
  }
  .home-like-image {
    height: 100%;
  }
  .div-other-like {
    height: auto;
    grid-row: span 1 / span 1;
    grid-column: span 2 / span 2;
  }
  .search-buttons-group {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 30px 16px;
    /* padding-left: 44px;
    padding-right: 44px; */
  }
  .image-liked-section {
    margin-top: 10px;
  }
  .image-popular-section {
    margin-top: 0;
  }
  .popular-search-image {
    width: 180px;
    height: 180px;
    top: 34px;
    left: -56px;
  }
  .why-images {
    width: 100px;
    height: 300px;
    top: -27px;
    right: -21px;
  }
  .main-why-section {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
  }
  .why-image-bazaar-section {
    width: 100%;
    margin-top: 30px;
  }
  .paragraph-why-section {
    margin-top: 15px !important;
  }
}

@media only screen and (min-width: 1256px) {
  .mainImagesPopular {
    column-count: 4;
  }
  .search-buttons-group {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 33px 18px;
    /* padding-left: 44px;
    padding-right: 44px; */
  }
  .image-liked-section {
    margin-top: 10px;
  }
  .why-images {
    width: 100px;
    height: 300px;
    top: -27px;
    right: -21px;
  }
  .popular-search-image {
    width: 180px;
    height: 180px;
    top: 34px;
    left: -56px;
  }
  .main-why-section {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 30px;
  }
  .why-image-bazaar-section {
    margin-top: 30px;
  }
  .paragraph-why-section {
    margin-top: 18px !important;
  }
}
