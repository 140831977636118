.change-password-div {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 50%;
  padding: 30px 50px 40px 50px;
}

.change-password-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 32px;
}

.change-password-submit {
  height: 67px;
  width: 100%;
  border: 0;
  outline: none;
  background-color: black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px !important;
  margin-top: 15px;
}

@media only screen and (min-width: 250px) and (max-width: 670px) {
  .change-password-div {
    width: auto;
    padding: 40px 30px 45px 30px;
  }

  .change-password-label {
    margin-left: 3px;
    font-size: 15px;
  }

  .change-password-submit {
    height: 50px;
    font-size: 15px;
  }
}
