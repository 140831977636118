.home-image-video {
  width: 147px;
  background-color: white;
  position: absolute;
  right: 0px;
  margin-top: 5px;
  border-radius: 10px;
  z-index: 999;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.095) !important;
}

.animation-transform {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.main-common-header {
  /* background: linear-gradient(180deg, #e2efff 0%, rgba(217, 217, 217, 0) 100%); */
  background: linear-gradient(180deg, #e7eef7 0%, rgba(217, 217, 217, 0) 100%);
  box-shadow: none;
}

.desktop-slider-Box {
  overflow: hidden;
}

.desktop-slider-Box:hover .desktop-zoom-image {
  transform: scale(1.1);
}

.desktop-zoom-image {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    transform 0.5s ease-out 0s, -webkit-transform 0.5s ease-out 0s;
  display: block;
}

.home-image-video p {
  font-size: 15px;
  font-weight: 600;
  padding: 20px 0px 20px 27px;
  line-height: 0px;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.home-image-video p:hover {
  background-color: #f5f5f5;
}

body {
  padding: 0 !important;
}

.main-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.filter-icon {
  height: 18px;
  position: relative;
  top: 3px;
}

.header-content {
  padding-left: 24px;
  margin-bottom: 40px;
  padding-right: 24px;
}

.header-content-cart {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  padding-top: 23px;
  padding-left: 24px;
  margin-bottom: 76px;
  padding-right: 24px;
}

.children-content {
  background-color: white;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
}

/* .mainHeader {
  display: flex;
  padding-left: 54px;
  padding-right: 54px;
  justify-content: space-between;
  position: fixed;
  width: calc(100% - 108px);
  z-index: 999999;
  height: 65px;
} */
.mainHeader {
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  position: fixed;
  /* width: calc(100% - 108px); */
  width: 100%;
  z-index: 2;
  height: 65px;
}

.mainHeader-layout {
  padding-left: 24px;
  padding-right: 24px;
}

.mainHeader-titleBar-layout {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-top: 1.2rem;
  padding-bottom: 15px;
}

.mainHeader-searchbar-layout {
  margin-top: 30px;
}

.logo-section {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.side-category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-imagesize {
  height: 55px;
  width: 55px;
  object-fit: cover;
  cursor: pointer;
}

.profile-dropdown-list {
  position: absolute;
  right: 15px;
  background: rgba(71, 71, 71, 0.97);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 15px 25px;
  border-radius: 18px;
  z-index: 5;
  color: #ffffff;
}

.main-account-menu-buttton {
  border: none;
  background: none;
  margin: none;
  color: white;
  display: flex;
  align-items: center;
  padding: none;
  margin-bottom: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.header-font {
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  text-transform: uppercase;
}

.transform-arrow-button {
  transform: rotate(180deg);
}

.main-account-menu-name {
  margin-left: 10px;
}

.account-menu-down-icon {
  width: 15px;
  margin-left: 40px;
  height: 15px;
  fill: white;
  /* transform: rotate(180deg); */
}

.logout-button-menu {
  margin-bottom: 0 !important;
}

.account-menu-group {
  margin-bottom: 15px;
}

.submenu-profile-items {
  margin-top: 10px;
}

.account-menu-icnos {
  width: 25px;
  height: 25px;
  fill: white;
}

.submenu-profile-items {
  margin-left: 10px;
}

.side-category2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-icon {
  margin-right: 15px;
  position: relative;
}

.user-name {
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
}

.profile-image-header {
  position: relative;
}

.sign-in-button {
  background: #272727;
  border-radius: 37px;
  padding: 10px 20px;
  border: 1px solid black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sign-in-button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.searchBarText {
  padding-top: 80px;
}

.searchText {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.searchText2 {
  color: white;
}

.wishlistMenuIcon {
  fill: white;
  width: 20px;
  height: 20px;
}

.countOnCartMenu {
  background-color: rgb(108, 108, 108) !important;
  color: white !important;
  font-weight: 600 !important;
}

.searchInput {
  display: flex;
  justify-content: center;
  padding: 0px 6px;
}
.searchInputForBrand {
  display: flex;
  justify-content: left;
  padding: 0px 6px;
}

.mainDivSearchBar {
  width: 1023px;
  position: relative;
}
.mainDivSearchBarForBrand {
  width: 1023px;
  position: relative;
}
.brandLogo {
  display: block; /* or any other default display value */
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .brandLogo {
    display: none;
  }
}

.searchBarItems {
  position: relative;
  padding: 10px 15px;
  display: flex;
  border: 1px solid gray;
  /* box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
    rgb(0 0 0 / 30%) 0px 8px 16px -8px; */
  background-color: white;
}

.search-image-section {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 140px;
}

.search-heroimage {
  width: 100% !important;
  /* max-width: 1023px !important; */
  margin-top: 40px !important;
}

.searchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
}

.searchInputType {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  width: 100%;
  height: 52px;
  border: none;
  max-width: 75%;
  color: #000000;
}

.dropDownSearch {
  border-left: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 155px;
}

.searchInputType:focus {
  outline: 0 !important;
}

.dropDownText {
  padding-left: 27px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}

.dropDownIcon {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}

.grayRound {
  width: 150px;
  top: -28px;
  left: -52px;
  z-index: 0;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(47.44% 47.44% at 17.7% 55.4%,
      #dedede 0%,
      #bcbcbc 100%);
}

.download-section {
  display: flex;
  justify-content: center;
  margin-top: 33px;
}

.free-download-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.download-icon {
  margin-left: 17px;
  display: flex;
}

.download-date {
  margin-left: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.download-date-text {
  margin-bottom: 10px;
}

.mainFooter {
  padding-top: 55px;
  color: white;
  background-color: white;
}

.footer-section {
  padding-top: 25px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: black;
}

.footerMainContent {
  width: 100%;
}

.main-footer-logo {
  width: 200px;
  height: 40px;
  cursor: pointer;
}

.main-footer-contact-email-icons {
  width: 27px;
  height: 18px;
}

.main-footer-contact-call-icons {
  width: 18px;
  height: 25px;
}

.footer-categories {
  margin-top: 34px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  width: 100%;
}

.info-section-footer {
  display: flex;
  flex-direction: column;
}

.info-name {
  display: flex;
  align-items: center;
}

.space-divider {
  margin-top: 15px !important;
}

.mail-link-footer {
  color: #cacaca;
  text-decoration: none;
}

.mail-link-footer:hover {
  color: white;
  font-weight: 700;
}

.info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  color: #cacaca;
}

.info-text:hover {
  color: white;
  font-weight: 700;
}

.info-text-call-number:hover {
  color: white;
  font-weight: 700;
}

.footer-info-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}

.info-social-icons {
  margin-top: 26px;
}

.footer-info-icons {
  display: flex;
}

.squareIcon {
  background: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon-group {
  margin-top: 10px;
  display: flex;
}

.footer-social-page {
  width: 30px;
  height: 30px;
}

.footer-menus {
  margin-top: 25px;
}

.footer-menu-group {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
}

.footer-rights {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.footer-rights-title {
  margin-top: 34px !important;
  margin-bottom: 10px !important;
}

.footer-policy {
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.footer-policy-section {
  margin-right: 250px;
  display: flex;
}

.custom-dialog-menu {
  margin-top: 42px;
  background-color: black;
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 58px;
  padding-right: 130px;
}

.custom-menu-items {
  display: flex;
  cursor: pointer;
  /* margin-bottom: 34px; */
  align-items: center;
}

.custom-menu-name {
  margin-left: 15px;
  font-family: "Poppins";
  font-style: normal;
  color: white;
  font-weight: 400;
  white-space: nowrap;
  font-size: 16px;
  line-height: 18px;
}

.policy-details-footer {
  cursor: pointer;
}

.menu-items .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background: #000000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  /* margin-top: 42px; */
  /* border-radius: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 58px;
  padding-right: 130px; */
}

.menu-items .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0;
}

.menu-items::-webkit-scrollbar {
  display: none;
  width: 0;
}

.menu-items-list {
  padding-top: 25px;
  padding-bottom: 35px;
  padding-left: 35px;
  padding-right: 70px;
}

.menu-item-list-container {
  display: flex;
  align-items: center;
}

.menu-items .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar {
  display: none;
}

.svg-class {
  width: 30px;
  height: 30px;
}

.moreinfo-wallpaper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  max-width: 1800px;
  width: 100%;
}

.moreinfo-wallpaper-vertical {
  max-width: 1200px !important;
}

.moreinfo-wallpaper-vertical img {
  max-height: 1800px !important;
}

.moreinfo-wallpaper-square {
  max-width: 1200px !important;
}

.moreinfo-wallpaper-square img {
  max-height: 1200px !important;
}

.moreinfo-wallpaper img {
  height: 100%;
  max-height: 1200px;
  width: 100%;
  margin-top: 0;
  object-fit: contain;
}

.more-info-halfcircle {
  display: none;
}

.moreinfo-wallpaper:hover .more-info-halfcircle {
  height: 250px;
  width: 450px;
  background: rgb(0, 0, 0, 0.8);
  position: absolute;
  bottom: 5px;
  left: 0;
  animation: fade-in 1s;
  right: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 250px;
  border-top-right-radius: 250px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  padding: 20px 10px 20px 35px;
}

.more-info-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.more-first-step {
  margin-top: 155px;
}

.more-first-step:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.more-second-1-step {
  margin-top: 83px;
  margin-left: -17px;
}

.more-second-1-step:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.more-second-2-step {
  margin-top: 83px;
  margin-left: 20px;
}

.more-second-2-step:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.more-third-1-step {
  margin-top: 17px;
  margin-left: -9px;
}

.more-third-1-step:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.more-third-2-step {
  margin-top: 17px;
  margin-left: 9px;
}

.more-third-2-step:hover {
  filter: invert(47%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.more-info-halfcircle p {
  color: #ffffff;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  margin-top: -47px;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.5px !important;
}

.moreinfo-image-text56 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 25px;
}

.moreinfo-imagelist {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 20px;
  gap: 20px;
}

.moreinfo-singleimage {
  height: 400px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.moreinfo-singleimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage-category-menu-button {
  display: flex !important;
  border: none;
  cursor: pointer !important;
  background: none;
  width: fit-content !important;
  justify-content: flex-start !important;
  padding: 0 !important;
}

.hamburger-menu {
  width: 20px;
  height: 20px;
  fill: black;
}

.header-menus {
  cursor: pointer;
}

.cart-hamburger-menu {
  fill: white;
}

.main-logo {
  height: 30px;
  width: 150px;
}

.ib-main-logo {
  width: 170px;
  height: 40px;
}

.ib-logo-login {
  width: 210px;
  height: 45px;
}

.image-liked-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-liked-title-img {
  width: 100px;
  height: 20px;
}

.payment-icons-footer {
  width: 250px;
  height: 50px;
}

.footer-menu-items-links {
  cursor: pointer;
  margin-bottom: 5px;
  color: #cacaca;
}

.footer-menu-items-links:hover {
  color: white;
  font-weight: 700;
}

.policy-details-footer:hover {
  color: white;
  font-weight: 700;
}

.homepage-menu-item {
  margin-bottom: 20px !important;
}

.custom-menu-name:hover {
  color: rgb(183, 150, 213) !important;
}

.info-text-email {
  margin-left: 14px;
}

.left-margin-footer {
  margin-left: 23px !important;
}

.main-pie-menu {
  display: none;
}

.main-pie-menu-information {
  display: none;
}

.main-pie-menu-wishlist {
  display: none;
}

.zoom-image-menu {
  display: none;
}

.pie-menu-main-div:hover .main-pie-menu {
  display: block;
  position: absolute;
  bottom: 4px;
  height: 90px;
  width: 180px;
  background: rgb(0, 0, 0, 0.6);
  animation: fade-in 1s;
  left: 0;
  right: 0;
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.image-menu-main {
  display: none;
}

.show-image-menu-w-hover .image-menu-main {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(0, 0, 0, 0.5);
  animation: fade-in 1s;
  z-index: 1;
}

.pie-menu-main-div:hover .image-menu-main {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgb(0, 0, 0, 0.5);
  animation: fade-in 1s;
  z-index: 1;
}

.image-menu-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 5px;
  padding-top: 5px;
  /* display: flex; */
  width: 100%;
  /* justify-content: space-between; */
}

.video-menu-container {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  /* display: flex; */
  width: 100%;
  /* justify-content: space-between; */
}

.image-menu-right-icon {
  display: flex;
  justify-content: flex-end;
}

.image-menu-zoom {
  display: none;
}

.show-image-menu-w-hover .image-menu-zoom {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
  /* background: rgb(0, 0, 0, 0.5); */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  animation: fade-in 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.pie-menu-main-div:hover .image-menu-zoom {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 5px;
  /* background: rgb(0, 0, 0, 0.5); */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  animation: fade-in 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.image-menu-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  justify-content: center;
}

.image-menu-vertical-items {
  display: flex;
  justify-content: center;
}

.image-menu-vertical-icon {
  width: 18px;
  height: 18px;
}

.image-menu-horizontal-icon {
  width: 18px;
  height: 18px;
}

.image-menu-icon {
  fill: white;
}

.image-menu-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-menu-main-div:hover .main-pie-menu-information {
  display: block;
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 400px;
  background: rgb(0, 0, 0, 0.6);
  animation: fade-in 1s;
  left: 0;
  right: 0;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.pie-menu-main-div:hover .main-pie-menu-wishlist {
  display: block;
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 400px;
  background: rgb(0, 0, 0, 0.6);
  animation: fade-in 1s;
  left: 0;
  right: 0;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.pie-menu-main-div:hover .zoom-image-menu {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 17px;
  background: rgb(0, 0, 0, 0.6);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  animation: fade-in 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.close-image-menu {
  display: none;
}

.pie-menu-main-div:hover .close-image-menu {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 17px;
  background: rgb(0, 0, 0, 0.6);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  animation: fade-in 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-image-icon {
  width: 25px;
  height: 25px;
  fill: white;
}

.close-image-icon {
  width: 18px;
  height: 18px;
  fill: white;
}

.close-image-icon:hover {
  fill: rgb(255, 111, 0);
}

.zoom-image-icon:hover {
  fill: rgb(255, 111, 0);
}

.main-pie-menu-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.main-pie-menu-grid-information {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.main-pie-menu-grid-wishlist {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.common-pie-icon {
  width: 20px;
  height: 20px;
  fill: white;
  cursor: pointer;
}

.common-pie-icon-information {
  width: 30px;
  height: 30px;
  fill: white;
  cursor: pointer;
}

.common-pie-icon-wishlist {
  width: 30px;
  height: 30px;
  fill: white;
  cursor: pointer;
}

.common-pie-menu {
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
}

.pie-menu-shoot {
  margin-top: 60px;
  margin-left: 10px;
}

.pie-menu-info {
  margin-top: 60px;
  margin-right: 10px;
}

.pie-menu-similar {
  margin-top: 30px;
  margin-right: 10px;
}

.pie-menu-wishlist {
  margin-top: 30px;
  margin-left: 10px;
}

.pie-menu-download {
  margin-top: 10px;
  margin-right: 5px;
}

.pie-menu-cart {
  margin-top: 10px;
  margin-left: 5px;
}

.pie-menu-shoot-info {
  margin-top: 150px;
  margin-left: 30px;
}

.pie-menu-similar-wishlist {
  margin-top: 150px;
  margin-left: 30px;
}

.pie-menu-wishlist-info {
  margin-top: 150px;
  margin-right: 30px;
}

.pie-menu-wishlist-wishlist {
  margin-top: 150px;
  margin-right: 30px;
}

.pie-menu-similar-info {
  margin-top: 80px;
  margin-left: -20px;
}

.pie-menu-download-wishlist {
  margin-top: 80px;
  margin-left: -20px;
}

.pie-menu-cart-info {
  margin-top: 80px;
  margin-right: -20px;
}

.pie-menu-cart-wishlist {
  margin-top: 80px;
  margin-right: -20px;
}

.pie-menu-download-info {
  margin-top: 30px;
}

.common-pie-icon:hover {
  fill: rgb(255, 111, 0);
}

.wishlisted-image-icon {
  fill: rgb(255, 111, 0);
}

.common-pie-icon-information:hover {
  fill: rgb(255, 111, 0);
}

.common-pie-icon-wishlist:hover {
  fill: rgb(255, 111, 0);
}

.pie-menu-title {
  display: flex;
  margin-top: -20px !important;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
}

.pie-menu-title-info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  margin-top: -30px !important;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
}

.main-responsive-image-list {
  margin-top: 40px;
}

.div-main-like img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-main-like {
  animation: fadeIn 1s;
}

.profile-dropdown-button {
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.browse-cat-side-drawer .MuiDrawer-paper {
  background: #1a1a1a !important;
  border-radius: 0px 10px 10px 0px !important;
}

.browse-category-main-drawer {
  height: 100%;
  padding: 10px 15px 0px 15px;
}

.stars-collection-category {
  min-width: 200px;
}

.close-drawer-menu-btn {
  display: flex;
  justify-content: flex-end;
}

.close-side-drawer-button {
  background: none;
  margin: 0;
  padding: 2px;
  cursor: pointer;
  border: none;
  margin-bottom: 5px;
}

.close-side-drawer {
  fill: white;
  width: 20px;
  height: 20px;
}

.side-drawer-menu-button {
  background: none;
  margin: 0;
  color: #ffffff;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: start;
  padding: 8px 15px;
  font-weight: 400;
}

.side-drawer-menu-button:hover {
  background: #f6faff;
  border-radius: 18px;
  color: #000000;
}

.side-drawer-button-main {
  margin-bottom: 5px;
}

.selected-menu-button {
  background: #f6faff;
  border-radius: 18px;
  color: #000000;
}

.backto-main-menu {
  fill: white;
  width: 12px;
  height: 12px;
}

/* custom-scrollbar */
.browse-cat-side-drawer .MuiDrawer-paper::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.browse-cat-side-drawer .MuiDrawer-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background: #2e2e2e;
}

/* Handle */
.browse-cat-side-drawer .MuiDrawer-paper::-webkit-scrollbar-thumb {
  background: #656565;
  border-radius: 10px;
}

/* Handle on hover */
.browse-cat-side-drawer .MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
  background: #5d5d5d;
}

/* custom-scrollbar */
.back-menu-button {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.main-menu-side-bar {
  color: white;
  margin-left: 10px;
  font-weight: 600;
}

.sub-menu-title {
  cursor: context-menu;
}

.pricing-header {
  margin-right: 10px;
}

.header-right-content {
  display: flex;
  align-items: center;
}

.wishlist-items-notification {
  fill: black;
  width: 20px;
  height: 20px;
}

.wishlist-header-notification {
  position: relative;
  margin-right: 10px;
}

.wishlist-notification-button {
  background: none;
  border: none;
  padding: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
}

.wishlist-number-count {
  position: absolute;
  padding: 2px 5px;
  background-color: #2e2e2e;
  color: white;
  top: -16px;
  left: 14px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
}

.user-textmobile-button {
  display: none;
}

.user-name-text {
  white-space: nowrap;
  max-width: 133px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}

.terms-of-use-footer {
  padding-right: 10px;
  border-right: 1px solid #9d9d9d;
}

.privacypolicy-footer {
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid #9d9d9d;
}

.faqs-footer {
  padding-left: 10px;
}

.footer-contact-icons {
  fill: white;
  width: 25px;
  height: 25px;
}

.custom-toast-with-icon {
  display: flex;
  align-items: center;
}

.custom-toast-icon {
  fill: black;
  margin-right: 20px;
}

.login-eye-icon {
  fill: black;
}

.BaseIconButton:hover .login-eye-icon {
  fill: white;
}

.image-free-box {
  margin: 60px -24px -42px -24px;
  position: relative;
}

.left-box-free-image {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 26.5%;
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.right-box-free-image {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 26.5%;
  height: calc(100% - 4px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text-font-free-download {
  font-size: 40px !important;
  line-height: 45px !important;
  text-align: center !important;
}

.freedownload-icon {
  width: 40px;
  height: 40px;
  fill: white;
}

.image-download-history {
  width: auto;
  height: 100px;
  max-width: 170px;
  border-radius: 10px;
}

.arroe-to-scroll {
  position: fixed;
  background-color: #212121;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 6rem;
  animation: scale-display 0.3s;
  right: 10px;
}

.chatbox-to-scroll {
  position: fixed;
  background-color: #1900ff;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 3rem;
  animation: scale-display 0.3s;
  right: 10px;
}

.filter-box {
  animation: scale-display 0.3s;
}

.heading-title-back-button {
  display: grid;
  align-items: center;
}

.heading-title-back-button>* {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: center;
}

.heading-title-back-button>*:first-child {
  justify-self: left;
}

iframe.component-frame {
  z-index: 0 !important;
}

.searchbar-select-options {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  right: 0;
}

.searchBar-select-button {
  padding-right: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
}

.searchbar-select-options-menu {
  position: absolute;
  right: 0;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  top: 100%;
}

.searchBar-select-box-options:hover {
  background-color: rgb(228, 228, 228);
}

.divider-search-bar {
  width: 2px;
  height: 60%;
  margin-right: 20px;
  background-color: rgba(205, 205, 205, 1);
}

.down-arrow-searchBar {
  width: 12px;
  margin-left: 10px;
  height: 12px;
  fill: black;
}

.searchBar-select-box-options {
  padding: 15px 8px;
  text-align: center;
}

.video-info-shoot-layout {
  display: grid;
  padding-top: 20px;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.show-videomoreinfo {
  min-height: 31.25rem;
  max-width: 1200px;
}

.homepage-hero-image {
  width: 100%;
  object-fit: cover;
}

@keyframes scale-display {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.video-gallery-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px;
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .mainHeader-searchbar-layout {
    margin-top: 20px;
  }

  .text-font-free-download {
    font-size: 10px !important;
    line-height: 12px !important;
    text-align: center !important;
  }

  .homepage-hero-image {
    display: none;
  }

  .freedownload-icon {
    width: 20px;
    height: 20px;
    fill: white;
  }

  .homepage-menu-item {
    margin-bottom: 0 !important;
  }

  .searchInputType {
    height: 40px;
    max-width: 70%;
  }

  .moreinfo-imagelist {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .search-image-section {
    margin-top: 80px;
  }

  .mainFooter {
    padding-top: 30px;
  }

  .menu-items-list {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 30px;
  }

  .custom-menu-name {
    margin-left: 20px;
    font-size: 14px;
    line-height: 16px;
  }

  .header-content {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;
  }

  .hamburger-menu {
    width: 20px;
    height: 20px;
  }

  .mainHeader {
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .mainHeader-layout {
    padding-left: 20px;
    padding-right: 20px;
  }

  .info-text-email {
    margin-left: 10px;
  }

  .left-margin-footer {
    margin-left: 15px !important;
  }

  .space-divider {
    margin-top: 10px;
  }

  .main-footer-contact-email-icons {
    width: 20px;
    height: 15px;
  }

  .main-footer-contact-call-icons {
    width: 15px;
    height: 20px;
  }

  .footer-icon-group {
    margin-top: 15px;
  }

  .footer-social-page {
    width: 25px;
    height: 25px;
  }

  .footer-section {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .searchIcon img {
    width: 20px;
    height: 20px;
  }

  .searchIcon {
    margin-right: 5px;
  }

  .download-section {
    margin-top: 40px;
  }

  .children-content {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
  }

  .image-free-box {
    margin: 60px -20px -42px -20px;
  }

  .header-font {
    display: none;
  }

  .main-logo {
    height: 28px;
    width: 120px;
  }

  .ib-main-logo {
    width: 130px;
    height: 30px;
  }

  .ib-logo-login {
    width: 130px;
    height: 30px;
  }

  .sign-in-button {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 14px;
  }

  .searchBarText {
    padding-top: 70px;
  }

  .grayRound {
    width: 90px;
    height: 90px;
    top: -18px;
    left: -16px;
  }

  .searchBarItems {
    padding: 5px 10px;
  }

  .dropDownIcon img {
    width: 10px;
    height: 10px;
  }

  /* .free-download-button {
    width: 280px;
    height: 60px;
  } */
  .download-date {
    margin-left: 25px;
  }

  .download-icon img {
    width: 18px;
    height: 18px;
  }

  .download-icon {
    margin-left: 12px;
  }

  .footer-policy {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .footer-rights-title {
    margin-top: 10px !important;
    line-height: 25px !important;
  }

  .footer-categories {
    margin-top: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .info-social-icons {
    margin-top: 15px;
  }

  .footer-menu-group {
    margin-top: 15px;
  }

  .footer-menus {
    margin-top: 10px;
  }

  .main-footer-logo {
    width: 150px;
    height: 33px;
  }

  .footer-policy-section {
    margin-right: 0;
  }

  .footerMainContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .home-image-video {
    width: 115px;
  }

  .home-image-video p {
    font-size: 14px;
    padding: 15px 0px 15px 32px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .main-pie-menu-grid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu {
    height: 100px;
    width: 200px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }

  .pie-menu-main-div:hover .zoom-image-menu {
    right: 17px;
    top: 17px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .zoom-image-icon {
    width: 20px;
    height: 20px;
  }

  .common-pie-icon {
    width: 20px;
    height: 20px;
  }

  .pie-menu-shoot {
    margin-top: 70px;
    margin-left: 0;
  }

  .pie-menu-info {
    margin-top: 70px;
    margin-right: 0px;
  }

  .pie-menu-similar {
    margin-top: 40px;
    margin-right: 10px;
  }

  .pie-menu-wishlist {
    margin-top: 40px;
    margin-left: 10px;
  }

  .pie-menu-download {
    margin-top: 15px;
    margin-right: 5px;
  }

  .pie-menu-cart {
    margin-top: 15px;
    margin-left: 5px;
  }

  .pie-menu-title {
    margin-top: -20px;
  }

  .main-pie-menu-grid-information {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu-information {
    height: 100px;
    width: 200px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }

  .pie-menu-main-div:hover .main-pie-menu-wishlist {
    height: 100px;
    width: 200px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }

  .pie-menu-main-div:hover .close-image-menu {
    right: 10px;
    top: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

  .close-image-icon {
    width: 10px;
    height: 10px;
  }

  .common-pie-icon-information {
    width: 20px;
    height: 20px;
  }

  .common-pie-icon-wishlist {
    width: 20px;
    height: 20px;
  }

  .pie-menu-shoot-info {
    margin-top: 70px;
    margin-left: 0;
  }

  .pie-menu-similar-wishlist {
    margin-top: 70px;
    margin-left: 0;
  }

  .pie-menu-wishlist-info {
    margin-top: 70px;
    margin-right: 0;
  }

  .pie-menu-wishlist-wishlist {
    margin-top: 70px;
    margin-right: 0;
  }

  .pie-menu-similar-info {
    margin-top: 35px;
    margin-left: -30px;
  }

  .pie-menu-download-wishlist {
    margin-top: 35px;
    margin-left: -30px;
  }

  .pie-menu-cart-info {
    margin-top: 35px;
    margin-right: -30px;
  }

  .pie-menu-cart-wishlist {
    margin-top: 35px;
    margin-right: -30px;
  }

  .pie-menu-download-info {
    margin-top: 10px;
  }

  .pie-menu-title-info {
    font-size: 12px;
    line-height: 14px;
    margin-top: -20px;
  }

  .wishlist-items-notification {
    width: 16px;
    height: 16px;
  }

  .wishlist-number-count {
    padding: 2px 4px;
    font-size: 12px;
    top: -14px;
    left: 18px;
    line-height: 13px;
  }

  .user-textmobile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  .user-name-text {
    display: none;
  }

  .mobile-menu-icon {
    fill: black;
    width: 16px;
    height: 16px;
  }

  .wishlistMenuIcon {
    fill: white;
    width: 16px;
    height: 16px;
  }

  .wishlist-header-notification {
    margin-right: 3px;
  }

  .divider-search-bar {
    margin-right: 10px;
  }

  .searchBar-select-button {
    padding-right: 10px;
  }

  .searchBar-select-box-options {
    padding: 10px 5px;
  }

  .video-gallery-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .video-info-shoot-layout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .show-videomoreinfo {
    min-height: auto;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .mainHeader-searchbar-layout {
    margin-top: 20px;
  }

  .text-font-free-download {
    font-size: 14px !important;
    line-height: 16px !important;
    text-align: center !important;
  }

  .freedownload-icon {
    width: 20px;
    height: 20px;
    fill: white;
  }

  .homepage-hero-image {
    display: none;
  }

  .search-image-section {
    margin-top: 80px;
  }

  .header-content {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 40px;
  }

  .moreinfo-imagelist {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .homepage-menu-item {
    margin-bottom: 10px !important;
  }

  .searchInputType {
    height: 40px;
    max-width: 70%;
  }

  .menu-items-list {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 30px;
  }

  .mainHeader {
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    padding-right: 30px;
    height: 55px;
    width: 100%;
  }

  .mainHeader-layout {
    padding-left: 30px;
    padding-right: 30px;
  }

  .custom-menu-name {
    margin-left: 20px;
    font-size: 14px;
    line-height: 16px;
  }

  .searchIcon {
    margin-right: 10px;
  }

  .download-section {
    margin-top: 40px;
  }

  .searchIcon img {
    width: 20px;
    height: 20px;
  }

  .children-content {
    padding-left: 30px;
    padding-right: 30px;
  }

  .image-free-box {
    margin: 60px -30px -42px -30px;
  }

  .header-font {
    display: none;
  }

  .main-logo {
    height: 35px;
    width: 150px;
  }

  .ib-main-logo {
    width: 130px;
    height: 30px;
  }

  .ib-logo-login {
    width: 130px;
    height: 30px;
  }

  .sign-in-button {
    padding: 7px 12px;
    font-size: 16px;
    line-height: 18px;
  }

  .mainFooter {
    padding-top: 30px;
  }

  .searchBarText {
    padding-top: 80px;
  }

  .grayRound {
    width: 100px;
    height: 100px;
    top: -22px;
    left: -28px;
  }

  .searchBarItems {
    padding: 5px 15px;
  }

  .dropDownIcon img {
    width: 10px;
    height: 10px;
  }

  /* .free-download-button {
    width: 250px;
    height: 55px;
  } */
  .footer-menus {
    margin-top: 10px;
  }

  .footer-categories {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 20px;
  }

  .footer-policy-section {
    margin-right: 0;
  }

  .download-date {
    margin-left: 25px;
  }

  .download-icon {
    margin-left: 12px;
  }

  .download-icon img {
    width: 18px;
    height: 20px;
  }

  .footer-section {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }

  .footer-policy {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .footer-rights-title {
    margin-top: 10px !important;
    line-height: 25px !important;
  }

  .info-social-icons {
    margin-top: 18px;
  }

  .footer-menu-group {
    margin-top: 15px;
  }

  .home-image-video {
    width: 130px;
  }

  .footerMainContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .home-image-video p {
    font-size: 15px;
    padding: 20px 0px 20px 32px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .info-text-email {
    margin-left: 10px;
  }

  .left-margin-footer {
    margin-left: 15px !important;
  }

  .space-divider {
    margin-top: 10px;
  }

  .main-footer-contact-email-icons {
    width: 20px;
    height: 15px;
  }

  .main-footer-contact-call-icons {
    width: 15px;
    height: 20px;
  }

  .footer-icon-group {
    margin-top: 15px;
  }

  .main-pie-menu-grid-information {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu-information {
    height: 120px;
    width: 240px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
  }

  .pie-menu-main-div:hover .main-pie-menu-wishlist {
    height: 120px;
    width: 240px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
  }

  .pie-menu-main-div:hover .close-image-menu {
    right: 10px;
    top: 0;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .close-image-icon {
    width: 15px;
    height: 15px;
  }

  .common-pie-icon-information {
    width: 25px;
    height: 25px;
  }

  .common-pie-icon-wishlist {
    width: 25px;
    height: 25px;
  }

  .pie-menu-shoot-info {
    margin-top: 85px;
    margin-left: 0;
  }

  .pie-menu-similar-wishlist {
    margin-top: 85px;
    margin-left: 0;
  }

  .pie-menu-wishlist-info {
    margin-top: 85px;
    margin-right: 0;
  }

  .pie-menu-wishlist-wishlist {
    margin-top: 85px;
    margin-right: 0;
  }

  .pie-menu-similar-info {
    margin-top: 45px;
    margin-left: -30px;
  }

  .pie-menu-download-wishlist {
    margin-top: 45px;
    margin-left: -30px;
  }

  .pie-menu-cart-info {
    margin-top: 45px;
    margin-right: -30px;
  }

  .pie-menu-cart-wishlist {
    margin-top: 45px;
    margin-right: -30px;
  }

  .pie-menu-download-info {
    margin-top: 20px;
  }

  .pie-menu-title-info {
    font-size: 14px;
    line-height: 15px;
    margin-top: -20px;
  }

  .user-textmobile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  .user-name-text {
    display: none;
  }

  .mobile-menu-icon {
    fill: black;
    width: 20px;
    height: 20px;
  }

  .wishlistMenuIcon {
    fill: white;
    width: 20px;
    height: 20px;
  }

  .divider-search-bar {
    margin-right: 10px;
  }

  .searchBar-select-button {
    padding-right: 20px;
  }

  .searchBar-select-box-options {
    padding: 10px 5px;
  }

  .video-gallery-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .video-info-shoot-layout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .show-videomoreinfo {
    min-height: auto;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .home-image-video {
    width: 140px;
  }

  .text-font-free-download {
    font-size: 18px !important;
    line-height: 20px !important;
    text-align: center !important;
  }

  .freedownload-icon {
    width: 25px;
    height: 25px;
    fill: white;
  }

  .home-image-video p {
    font-size: 14px;
    padding: 20px 0px 20px 32px;
  }

  .moreinfo-imagelist {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .menu-items-list {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 30px;
  }

  .custom-menu-name {
    margin-left: 20px;
    font-size: 16px;
    line-height: 18px;
  }

  .main-footer-logo {
    width: 150px;
    height: 35px;
  }

  .mainHeader {
    height: 55px;
  }

  .download-icon img {
    width: 20px;
    height: 20px;
  }

  .download-date {
    margin-left: 25px;
  }

  /* .free-download-button {
    width: 250px;
    height: 55px;
  } */
  .download-section {
    margin-top: 30px;
  }

  .dropDownIcon img {
    width: 12px;
    height: 12px;
  }

  .searchBarItems {
    padding: 5px 15px;
  }

  .searchIcon img {
    width: 25px;
    height: 25px;
  }

  .header-font {
    display: none;
  }

  .grayRound {
    width: 110px;
    height: 110px;
    top: -23px;
    left: -42px;
  }

  .menu-icon {
    margin-right: 0;
  }

  .header-content {
    margin-bottom: 40px;
  }

  .footer-section {
    padding-top: 20px;
  }

  .footer-menus {
    margin-top: 15px;
  }

  .footer-policy {
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .footer-rights-title {
    margin-top: 18px !important;
  }

  .footer-categories {
    margin-top: 20px;
  }

  .info-social-icons {
    margin-top: 18px;
  }

  .footer-policy-section {
    margin-right: 70px;
  }

  .mainFooter {
    padding-top: 35px;
  }

  .main-pie-menu-grid {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pie-menu-main-div:hover .main-pie-menu {
    height: 90px;
    width: 180px;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
  }

  .pie-menu-main-div:hover .zoom-image-menu {
    right: 10px;
    top: 10px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }

  .zoom-image-icon {
    width: 22px;
    height: 22px;
  }

  .common-pie-icon {
    width: 18px;
    height: 18px;
  }

  .pie-menu-shoot {
    margin-top: 60px;
    margin-left: 10;
  }

  .pie-menu-info {
    margin-top: 60px;
    margin-right: 10;
  }

  .pie-menu-similar {
    margin-top: 35px;
    margin-left: -10px;
    margin-right: 0;
  }

  .pie-menu-wishlist {
    margin-top: 35px;
    margin-left: 0;
    margin-right: -10px;
  }

  .pie-menu-download {
    margin-top: 15px;
    margin-right: 5px;
  }

  .pie-menu-cart {
    margin-top: 15px;
    margin-left: 5px;
  }

  .pie-menu-title {
    margin-top: -25px;
  }

  .main-pie-menu-grid-information {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu-information {
    height: 120px;
    width: 240px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
  }

  .pie-menu-main-div:hover .main-pie-menu-wishlist {
    height: 120px;
    width: 240px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
  }

  .pie-menu-main-div:hover .close-image-menu {
    right: 10px;
    top: 0;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .close-image-icon {
    width: 15px;
    height: 15px;
  }

  .common-pie-icon-information {
    width: 25px;
    height: 25px;
  }

  .common-pie-icon-wishlist {
    width: 25px;
    height: 25px;
  }

  .pie-menu-shoot-info {
    margin-top: 85px;
    margin-left: 0;
  }

  .pie-menu-similar-wishlist {
    margin-top: 85px;
    margin-left: 0;
  }

  .pie-menu-wishlist-info {
    margin-top: 85px;
    margin-right: 0;
  }

  .pie-menu-wishlist-wishlist {
    margin-top: 85px;
    margin-right: 0;
  }

  .pie-menu-similar-info {
    margin-top: 45px;
    margin-left: -30px;
  }

  .pie-menu-download-wishlist {
    margin-top: 45px;
    margin-left: -30px;
  }

  .pie-menu-cart-info {
    margin-top: 45px;
    margin-right: -30px;
  }

  .pie-menu-cart-wishlist {
    margin-top: 45px;
    margin-right: -30px;
  }

  .pie-menu-download-info {
    margin-top: 20px;
  }

  .pie-menu-title-info {
    font-size: 14px;
    line-height: 15px;
    margin-top: -20px;
  }

  .divider-search-bar {
    margin-right: 20px;
  }

  .searchBar-select-button {
    padding-right: 30px;
  }

  .searchBar-select-box-options {
    padding: 10px 5px;
  }

  .video-gallery-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .video-info-shoot-layout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .show-videomoreinfo {
    min-height: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .header-font {
    font-size: 14px;
  }

  .text-font-free-download {
    font-size: 20px !important;
    line-height: 22px !important;
    text-align: center !important;
  }

  .freedownload-icon {
    width: 30px;
    height: 30px;
    fill: white;
  }

  .hamburger-menu {
    width: 16px;
    height: 16px;
  }

  .menu-items-list {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 35px;
  }

  .custom-menu-name {
    margin-left: 20px;
    font-size: 16px;
    line-height: 18px;
  }

  .download-icon img {
    width: 20px;
    height: 20px;
  }

  .grayRound {
    width: 110px;
    height: 110px;
    top: -23px;
    left: -42px;
  }

  .download-date {
    margin-left: 25px;
  }

  .dropDownIcon img {
    width: 12px;
    height: 12px;
  }

  .searchIcon img {
    width: 25px;
    height: 25px;
  }

  .searchBarItems {
    padding: 5px 15px;
  }

  .header-content {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 40px;
  }

  .children-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mainFooter {
    padding-top: 35px;
  }

  .footer-section {
    padding-top: 20px;
  }

  .footer-categories {
    margin-top: 20px;
  }

  .footer-menus {
    margin-top: 15px;
  }

  .footer-rights-title {
    margin-top: 25px !important;
  }

  .main-pie-menu-grid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu {
    height: 90px;
    width: 180px;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
  }

  .pie-menu-main-div:hover .zoom-image-menu {
    right: 10px;
    top: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

  .zoom-image-icon {
    width: 22px;
    height: 22px;
  }

  .common-pie-icon {
    width: 18px;
    height: 18px;
  }

  .pie-menu-shoot {
    margin-top: 65px;
    margin-left: 0;
  }

  .pie-menu-info {
    margin-top: 65px;
    margin-right: 0px;
  }

  .pie-menu-similar {
    margin-top: 40px;
    margin-right: 10px;
  }

  .pie-menu-wishlist {
    margin-top: 40px;
    margin-left: 10px;
  }

  .pie-menu-download {
    margin-top: 15px;
    margin-right: 5px;
  }

  .pie-menu-cart {
    margin-top: 15px;
    margin-left: 5px;
  }

  .pie-menu-title {
    margin-top: -20px;
  }

  .video-gallery-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1450px) {
  .hamburger-menu {
    width: 18px;
    height: 18px;
  }

  .mainFooter {
    padding-top: 50px;
  }

  .grayRound {
    width: 120px !important;
    height: 120px !important;
    top: -22px !important;
    left: -48px !important;
  }

  .footer-section {
    padding-top: 20px;
  }

  .footer-categories {
    margin-top: 20px;
  }

  .footer-menus {
    margin-top: 15px;
  }

  .footer-rights-title {
    margin-top: 25px !important;
  }

  .footer-policy {
    margin-bottom: 15px;
  }

  .main-pie-menu-grid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pie-menu-main-div:hover .main-pie-menu {
    height: 90px;
    width: 180px;
    border-top-left-radius: 90px;
    border-top-right-radius: 90px;
  }

  .pie-menu-main-div:hover .zoom-image-menu {
    right: 17px;
    top: 17px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .zoom-image-icon {
    width: 20px;
    height: 20px;
  }

  .common-pie-icon {
    width: 18px;
    height: 18px;
  }

  .pie-menu-shoot {
    margin-top: 65px;
    margin-left: -5px;
  }

  .pie-menu-info {
    margin-top: 65px;
    margin-right: -5px;
  }

  .pie-menu-similar {
    margin-top: 35px;
    margin-right: 0;
    margin-left: -10px;
  }

  .pie-menu-wishlist {
    margin-top: 35px;
    margin-left: 0;
    margin-right: -10px;
  }

  .pie-menu-download {
    margin-top: 15px;
    margin-right: 0;
  }

  .pie-menu-cart {
    margin-top: 15px;
    margin-left: 0;
  }

  .pie-menu-title {
    margin-top: -20px;
  }

  .video-gallery-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1256px) {
  .menu-items-list {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 45px;
  }

  .custom-menu-name {
    margin-left: 15px;
    font-size: 16px;
    line-height: 18px;
  }

  .children-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .grayRound {
    width: 130px;
    height: 130px;
    top: -28px;
    left: -52px;
  }

  .searchBarItems {
    padding: 10px 15px;
  }

  .searchIcon img {
    width: 30px;
    height: 30px;
  }

  /* .free-download-button {
    width: 300px;
    height: 70px;
  } */
  .download-date {
    margin-left: 25px;
  }
  .headerImageModel{
    height: 20%
  }
}