.main-cart-item-layout {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  margin-top: 25px;
  padding: 25px;
}

.main-image-cart-div {
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.cart-item-image-main {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-image-data {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-main-image {
  max-width: 90%;
  height: auto;
  max-height: 200px;
  min-height: 100%;
  border-radius: 15px;
}

.cart-image-name {
  font-weight: 600;
  color: #212121;
}

.cart-image-type {
  font-weight: 400;
  color: #212121;
  margin-top: 20px;
}

.cart-file-type {
  font-weight: 400;
  color: #212121;
  margin-top: 5px;
}

.cart-image-price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #16a513;
  margin-top: 10px;
}

.cart-data-main-image {
  margin-top: 15px;
  text-transform: uppercase;
}

.cart-delete-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.cart-delete-icon {
  width: 30px;
  height: 30px;
}

.main-div-cart-total {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding: 0px;
  width: 100%;
  /* margin-left: auto;
  margin-right: auto; */
}

.price-details-info {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  margin-bottom: 10px;
}

.cart-pricing-detail {
  padding: 48px 40px 20px 40px;
}

.cart-total-price-checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f1f1f1;
}

.add-more-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.cart-items-list {
  height: auto;
  /* padding: 0px 20px 20px 20px; */
  max-height: 500px;
  overflow-y: scroll;
}

.cart-main-checkout-btn {
  background: #000000;
  border-radius: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  padding: 10px 20px;
}

.cart-main-addmore-btn {
  background: #000000;
  border-radius: 14px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 30px;
}

.cart-amount-wid2 {
  width: 40%;
  background-color: #f1f1f1;
}

.cart-amount-total {
  color: #8f8989;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-top: 38px;
}

.amount-total-money {
  color: #000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-top: -19px;
}

.cart-checkout-button {
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  border: 0;
  outline: none;
  background-color: black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px !important;
  margin-top: -8px;
}

.add-moreimage-button {
  height: 60px;
  width: 27%;
  border: 0;
  outline: none;
  background-color: black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px !important;
  margin-top: 30px;
}

.addtocartpage {
  display: flex;
}

.addtocart-image-main {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addtocart-table-main {
  width: 100%;
}

.addtocart-main-image {
  width: 80%;
  height: 250px;
  border-radius: 20px;
  object-fit: cover;
}

.cart-backgraound-image {
  width: 100%;
  margin-top: -119px;
  height: 600px;
}

.checkout-box-layout {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 30px 40px;
}

.order-height {
  height: auto;
  max-height: 575px;
  overflow-y: auto;
}

.checkout-pg-layout {
  display: flex;
  width: 100%;
}

.sidebar-paymentLayout {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentLayout-childen {
  width: 100%;
  padding-left: 50px;
}

.checkoutpayment-optionsection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.checkout-pg-options {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.border-bottom {
  border-bottom: 1px solid #cccccc;
}

.radio-button {
  margin: 0;
}

.checkout-item-card {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  margin-top: 10px;
  align-items: center;
}

.checkout-item-image {
  width: 100%;
  border-radius: 5px;
  height: auto;
}

.viewcart-layout {
  display: grid;
  margin-top: 10px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 20px;
}

.viewcart-card {
  grid-column: span 8 / span 8;
  padding: 0px 10px 10px 10px;
  height: auto;
  max-height: 500px;
  overflow-y: auto;
}

.viewcart-pricing {
  grid-column: span 4 / span 4;
}

.checkout-items-list {
  height: auto;
  max-height: 500px;
  overflow-y: auto;
}

@media only screen and (min-width: 280px) and (max-width: 500px) {
  .cart-backgraound-image {
    object-fit: cover;
    height: 400px;
  }

  .viewcart-card {
    grid-column: span 12 / span 12;
  }

  .viewcart-pricing {
    grid-column: span 12 / span 12;
  }

  .cart-image-price {
    font-size: 18px;
  }

  .main-cart-item-layout {
    flex-direction: column;
    margin-top: 20px;
  }

  .cart-delete-icon {
    width: 25px;
    height: 25px;
  }

  .checkout-box-layout {
    padding: 20px 15px;
  }

  .checkout-pg-layout {
    flex-direction: column;
  }

  .sidebar-paymentLayout {
    width: 100%;
    flex-direction: row;
    overflow: scroll;
    margin-bottom: 20px;
    align-items: center;
  }

  .paymentLayout-childen {
    padding-left: 0;
  }

  .checkout-item-card {
    gap: 10px;
  }

  .cart-data-main-image {
    margin-top: 20px;
  }

  .main-image-cart-div {
    width: 100%;
  }

  .cart-item-image-main {
    width: 100%;
  }

  .main-div-cart-total {
    flex-direction: column;
    width: 100%;
  }

  .cart-pricing-detail {
    padding: 20px;
  }

  .cart-total-price-checkout {
    padding: 10px 0px;
  }

  .addtocartpage {
    flex-direction: column;
  }

  .addtocart-image-main {
    width: 100%;
    margin-bottom: 15px;
  }

  .addtocart-main-image {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 501px) and (max-width: 749px) {
  .checkout-box-layout {
    padding: 20px 15px;
  }

  .checkout-pg-layout {
    flex-direction: column;
  }

  .viewcart-card {
    grid-column: span 12 / span 12;
  }

  .viewcart-pricing {
    grid-column: span 12 / span 12;
  }

  .sidebar-paymentLayout {
    width: 100%;
    flex-direction: row;
    overflow: scroll;
    margin-bottom: 20px;
    align-items: center;
  }

  .paymentLayout-childen {
    padding-left: 0;
  }

  .cart-backgraound-image {
    object-fit: cover;
    height: 400px;
  }

  .cart-image-price {
    font-size: 18px;
  }

  .main-cart-item-layout {
    flex-direction: column;
    margin-top: 20px;
  }

  .cart-delete-icon {
    width: 25px;
    height: 25px;
  }

  .cart-data-main-image {
    margin-top: 20px;
  }

  .main-image-cart-div {
    width: 100%;
  }

  .cart-item-image-main {
    width: 100%;
  }

  .main-div-cart-total {
    flex-direction: column;
    width: 100%;
  }

  .cart-pricing-detail {
    padding: 25px;
  }

  .cart-total-price-checkout {
    padding: 10px 0px;
  }

  .addtocartpage {
    flex-direction: column;
  }

  .addtocart-image-main {
    width: 60%;
    margin-bottom: 15px;
  }

  .addtocart-main-image {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .checkout-box-layout {
    padding: 20px 15px;
  }

  .checkout-pg-layout {
    flex-direction: column;
  }

  .viewcart-card {
    grid-column: span 12 / span 12;
  }

  .viewcart-pricing {
    grid-column: span 12 / span 12;
  }

  .sidebar-paymentLayout {
    width: 100%;
    flex-direction: row;
    overflow: scroll;
    margin-bottom: 20px;
    align-items: center;
  }

  .paymentLayout-childen {
    padding-left: 0;
  }

  .main-image-cart-div {
    width: 100%;
  }

  .main-cart-item-layout {
    padding: 15px;
  }

  .cart-item-image-main {
    width: 60%;
  }

  .cart-delete-icon {
    width: 25px;
    height: 25px;
  }

  .main-div-cart-total {
    flex-direction: column;
    width: 100%;
  }

  .cart-pricing-detail {
    padding: 20px;
  }

  .cart-total-price-checkout {
    padding: 10px 0px;
  }

  .addtocartpage {
    flex-direction: column;
  }

  .addtocart-image-main {
    width: 40%;
    margin-bottom: 15px;
  }

  .addtocart-main-image {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .main-image-cart-div {
    width: 100%;
  }

  .main-cart-item-layout {
    padding: 20px;
  }

  .addtocartpage {
    flex-direction: column;
  }

  .addtocart-main-image {
    width: auto;
  }

  .cart-item-image-main {
    width: 60%;
  }

  .addtocart-table-main {
    margin-top: 20px;
  }

  .cart-delete-icon {
    width: 25px;
    height: 25px;
  }

  .addtocart-image-main {
    width: 100%;
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .addtocart-image-main {
    width: 35%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .checkout-item-card {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}