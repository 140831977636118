.title-forgotPass {
  text-align: center;
  margin-bottom: 29px !important;
}

.forgot-button-main {
  margin-top: 25px;
}

.forgotButton-button-main {
  margin-top: 25px;
}

.forgotButton-button-step2 {
  margin-top: 10px;
}