.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 99%;
  width: auto;
  /* padding-top: 70px; */
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}
/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 10px;
  cursor: pointer;
}
.my-masonry-grid_column > div > img {
  width: 100%;
  border-radius: 2px;
  position: relative;
  transition: all 0.3s ease;
  /* filter: brightness(80%); */
}
.my-masonry-grid_column > div > img:hover {
  /* filter: brightness(100%); */
  transform: scale(1.01);
  z-index: 50;
  box-shadow: 0px 0px 5px 2px rgb(48, 47, 47);
}

.custom-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 3px;
  line-height: 0;
}

.custom-gallery::after {
  content: "";
  flex-grow: 1000;
}

.custom-gallery .imageItem-Vertical {
  flex-grow: 66.667;
  flex-basis: 192px;
  margin: 3px;
  position: relative;
}

.custom-gallery .imageItem-Horizontal {
  flex-grow: 150;
  flex-basis: 432px;
  margin: 3px;
  position: relative;
}

.custom-gallery .imageItem-square {
  flex-grow: 100.5;
  flex-basis: 287.5px;
  margin: 3px;
  position: relative;
}
.custom-gallery .imageItem-HorizontalContributor {
  flex-grow: 150;
  flex-basis: 300px;
  margin: 3px;
  position: relative;
}
.custom-gallery .imageItem-VerticalContributor {
  flex-grow: 66.667;
  flex-basis: 133px;
  margin: 3px;
  position: relative;
}

.custom-gallery img {
  cursor: pointer;
  transition: all 0.5s;
  width: 100%;
}
/* 
.custom-gallery img:hover {
  transform: scale(1.01);
  z-index: 1;
  position: relative;
} */
