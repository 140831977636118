.image-research-info {
  text-align: center;
  padding: 30px 50px;
}
.research-info-image {
  width: 110px;
  height: 95px;
  position: absolute;
  top: -60px;
  right: -20px;
}
.research-image-content {
  text-align: center;
  margin-top: 14px !important;
  color: #000000;
}
.image-research-form {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  line-height: 38px;
}
.image-research-main-content {
  padding: 30px 50px;
  font-family: "Poppins";
  font-style: normal;
}
.research-form-title {
  text-align: left;
  color: black;
}
.contactus-form-title {
  font-weight: 600;
  text-align: left;
  color: black;
}
.research-main-form {
  margin-top: 30px;
}
.research-form-content {
  margin-bottom: 20px;
}
.bottom-space-20 {
  margin-bottom: 20px;
}
.bottom-space-10 {
  margin-bottom: 10px;
}
.top-space-20 {
  margin-top: 20px;
}
.top-space-10 {
  margin-top: 10px;
}
.top-space-5 {
  margin-top: 5px;
}
.top-space-2 {
  margin-top: 2px;
}
.terms-check-notice-section {
  display: flex;
}
.confirmOrderSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.terms-checkbox {
  margin: 0;
  width: 22px;
  cursor: pointer;
  height: 22px;
  accent-color: black;
}
/* .common-checkbox {
  margin: 0;
  width: 18px;
  cursor: pointer;
  height: 18px;
  accent-color: black;
} */
.research-content-subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 15px;
  margin-left: 41px;
}
.research-content-input {
  border: 2px solid #d2d2d2;
  border-radius: 14px;
  padding: 20px 0px 20px 30px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  align-items: center;
}
.research-input {
  border: none;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: "Poppins";
  font-style: normal;
  color: #9f9f9f;
  margin-left: 18px;
}
.researchform-field-icons {
  width: 25px;
  height: 25px;
  fill: rgb(52, 52, 52);
}
.disabled-form-cursor {
  cursor: not-allowed !important;
}
.disabled-icons {
  fill: rgb(160, 160, 160) !important;
}
.research-input::-webkit-input-placeholder {
  color: #9f9f9f;
}

.research-input:-ms-input-placeholder {
  color: #9f9f9f;
}

.research-input::placeholder {
  color: #9f9f9f;
}
.research-input:focus {
  outline: 0 !important;
}
.research-input-without-icon {
  margin-left: 0;
}
.random-text-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.research-form-random-text {
  background: #7c7c7c;
  border-radius: 14px;
  padding-top: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 18px;
  padding-left: 41px;
}
.research-random-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: #e3e3e3;
  margin-right: 20px;
}
.research-form-submit-button {
  background: #000000;
  border-radius: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.research-form-submit-button:hover {
  background: #fff;
  color: black;
}
.search-image-main-content {
  position: relative;
}
.research-form-search-image {
  position: absolute;
  /* top: 30px;
  left: -345px; */
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  right: -54px;
  bottom: 50px;
  width: 200px;
}
.research-content-input svg {
  width: 30px;
  height: 30px;
}
.random-text-icon svg {
  width: 30px;
  height: 30px;
}
.imageresearch-hero-image {
  width: 320px;
  height: 320px;
  margin-top: -48px;
}
.main-research-grid {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  background: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
}
.textfield-research-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.textfield-research-form .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.textfield-research-form .MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
}
.textfield-research-form .MuiOutlinedInput-input {
  color: #9f9f9f !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 20px 5px !important;
}
.textfield-research-form .MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
}
.textfield-research-form {
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
}
.textfield-without-icon .MuiOutlinedInput-input {
  padding: 20px 0px !important;
}
.contactus-main-section {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  background: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
}
.contactus-form-section {
  padding: 50px;
}
.contactus-email-section {
  margin-top: 40px;
}
.contactus-texts {
  font-weight: 400;
  line-height: 20px;
}
.contactus-mobilenumbers {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .main-research-grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .contactus-main-section {
    display: flex;
    flex-direction: column;
  }
  .image-research-form {
    width: 100%;
  }
  .research-info-image {
    top: -50px;
    right: -10px;
    width: 90px;
    height: 75px;
  }
  .image-research-info {
    padding: 25px 10px;
  }
  .imageresearch-hero-image {
    width: 250px;
    height: 250px;
    margin-top: -40px;
  }
  .research-image-content {
    margin-top: 20px !important;
  }
  .image-research-form {
    margin-top: 50px;
  }
  .image-research-main-content {
    padding: 0px 10px 20px 10px;
  }
  .research-main-form {
    margin-top: 25px;
  }
  .research-content-subtitle {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    margin-bottom: 15px;
    margin-left: 10px;
  }
  .research-content-input {
    border: 1px solid #d2d2d2;
    padding: 10px 0 10px 15px;
  }
  .research-content-input svg {
    width: 20px;
    height: 20px;
  }
  .research-input {
    font-size: 14px;
    line-height: 16px;
    margin-left: 13px;
  }
  .research-input-without-icon {
    margin-left: 0;
  }
  .research-form-search-image {
    width: 70px;
    height: 70px;
    right: -20px;
    bottom: 70px;
  }
  .research-form-random-text {
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  .research-random-text {
    font-size: 16px;
    line-height: 18px;
    margin-right: 15px;
  }
  .random-text-icon svg {
    width: 20px;
    height: 20px;
  }
  .research-form-submit-button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 18px;
  }
  .textfield-research-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .contactus-form-section {
    padding: 20px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .main-research-grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .contactus-main-section {
    display: flex;
    flex-direction: column;
  }
  .image-research-form {
    width: 100%;
  }
  .research-info-image {
    top: -60px;
    right: -15px;
    width: 100px;
    height: 85px;
  }
  .imageresearch-hero-image {
    width: 250px;
    height: 250px;
    margin-top: -36px;
  }
  .image-research-info {
    padding: 30px 15px;
  }
  .research-image-content {
    margin-top: 22px !important;
  }
  .image-research-form {
    margin-top: 50px;
  }
  .image-research-main-content {
    padding: 0px 15px 20px 15px;
  }
  .research-main-form {
    margin-top: 25px;
  }
  .research-content-subtitle {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    margin-bottom: 18px;
    margin-left: 13px;
  }
  .research-content-input {
    border: 1px solid #d2d2d2;
    padding: 15px 0 15px 20px;
  }
  .research-content-input svg {
    width: 20px;
    height: 20px;
  }
  .research-input {
    font-size: 16px;
    line-height: 18px;
    margin-left: 13px;
  }
  .research-input-without-icon {
    margin-left: 0;
  }
  .research-form-search-image {
    width: 90px;
    height: 90px;
    right: -20px;
    bottom: 70px;
  }
  .research-form-random-text {
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  .research-random-text {
    font-size: 18px;
    line-height: 20px;
    margin-right: 18px;
  }
  .random-text-icon svg {
    width: 25px;
    height: 25px;
  }
  .research-form-submit-button {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 18px;
  }
  .textfield-research-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .contactus-form-section {
    padding: 20px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .image-research-form {
    width: calc(100% - 50px);
  }
  .main-research-grid {
    display: flex;
    flex-direction: column-reverse;
  }
  .contactus-main-section {
    display: flex;
    flex-direction: column;
  }
  .research-info-image {
    top: -70px;
    right: -15px;
    width: 120px;
    height: 100px;
  }
  .imageresearch-hero-image {
    width: 250px;
    height: 250px;
    margin-top: -36px;
  }
  .image-research-info {
    padding: 40px 25px;
  }
  .research-image-content {
    margin-top: 25px !important;
  }
  .image-research-form {
    margin-top: 50px;
  }
  .image-research-main-content {
    padding: 0px 25px 20px 25px;
  }
  .research-main-form {
    margin-top: 25px;
  }
  .research-content-subtitle {
    font-size: 16px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 18px;
    margin-left: 13px;
  }
  .research-content-input {
    border: 1px solid #d2d2d2;
    padding: 20px 0 20px 25px;
  }
  .research-content-input svg {
    width: 25px;
    height: 25px;
  }
  .research-input {
    font-size: 18px;
    line-height: 20px;
    margin-left: 15px;
  }
  .research-input-without-icon {
    margin-left: 0;
  }
  .research-form-search-image {
    width: 150px;
    height: 150px;
    right: -54px;
    bottom: 68px;
  }
  .research-form-random-text {
    margin-top: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
  }
  .research-random-text {
    font-size: 20px;
    line-height: 22px;
    margin-right: 20px;
  }
  .random-text-icon svg {
    width: 30px;
    height: 30px;
  }
  .research-form-submit-button {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    line-height: 18px;
  }
  .textfield-research-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .contactus-form-section {
    padding: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .image-research-form {
    width: calc(100% - 150px);
  }
  .research-info-image {
    top: -70px;
    right: -15px;
    width: 130px;
    height: 110px;
  }
  .contactus-main-section {
    display: flex;
    flex-direction: column;
  }
  .image-research-info {
    padding: 45px 30px;
  }
  .research-image-content {
    margin-top: 20px !important;
  }
  .image-research-form {
    margin-top: 50px;
  }
  .image-research-main-content {
    padding: 45px 30px;
  }
  .research-main-form {
    margin-top: 25px;
  }
  .research-content-subtitle {
    font-size: 16px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
    margin-left: 15px;
  }
  .research-content-input {
    border: 1px solid #d2d2d2;
    padding: 15px 0 15px 15px;
  }
  .research-content-input svg {
    width: 30px;
    height: 30px;
  }
  .research-input {
    font-size: 16px;
    line-height: 20px;
    margin-left: 15px;
  }
  .research-input-without-icon {
    margin-left: 0;
  }
  .research-form-search-image {
    width: 200px;
    right: -54px;
    bottom: 50px;
  }
  .research-form-random-text {
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
  }
  .research-random-text {
    font-size: 18px;
    line-height: 20px;
    margin-right: 22px;
  }
  .random-text-icon svg {
    width: 30px;
    height: 30px;
  }
  .research-form-submit-button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 20px;
  }
  .textfield-research-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .image-research-form {
    width: calc(100% - 260px);
  }
  .research-info-image {
    top: -70px;
    right: -15px;
    width: 130px;
    height: 110px;
  }
  .imageresearch-hero-image {
    width: 300px;
    height: 300px;
    margin-top: -46px;
  }
}
