.full-screen-image {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-screen-image img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}
.slider-wrap-div {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.full-screen-image p {
  font-size: 22px;
  letter-spacing: 0.7px;
  position: fixed;
  cursor: pointer;
  color: white;
  top: 20px;
  left: 40px;
}

.slider-wrap-div .close-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 40px;
  right: 40px;
  height: 20px;
}

.slider-wrap-div .close-modal-wrap:hover,
.slider-wrap-div .prev-modal-wrap:hover,
.slider-wrap-div .next-modal-wrap:hover {
  opacity: 1;
}

.slider-wrap-div .close-modal-wrapping:hover,
.slider-wrap-div .prev-modal-wrapping:hover,
.slider-wrap-div .next-modal-wrapping:hover {
  opacity: 1;
}

.slider-wrap-div .prev-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  transform: translate(-50%);
  left: 76px;
  height: 40px;
}

.slider-wrap-div .next-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  transform: translate(-50%);
  right: 0px;
  height: 40px;
}

.slider-wrap-div .prev-modal-wrapping {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  transform: translate(-50%);
  left: 76px;
  height: 40px;
}


.slider-wrap-div .next-modal-wrapping {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  /* transform: translate(-50%); */
  right: 0px;
  height: 40px;
}





.sliderContributor-wrap-div {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.full-screen-Contributor-image p {
  font-size: 22px;
  letter-spacing: 0.7px;
  position: fixed;
  cursor: pointer;
  color: white;
  top: 20px;
  left: 40px;
}

.sliderContributor-wrap-div .close-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 40px;
  right: 40px;
  height: 20px;
}

.sliderContributor-wrap-div .close-modal-wrap:hover,
.sliderContributor-wrap-divslider-wrap-div .prev-modal-wrap:hover,
.sliderContributor-wrap-div .next-modal-wrap:hover {
  opacity: 1;
}

.sliderContributor-wrap-div .prev-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  transform: translate(-50%);
  left: 76px;
  height: 40px;
}

.sliderContributor-wrap-div .next-modal-wrap {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  transform: translate(-50%);
  right: 0px;
  height: 40px;
}

.sliderContributor-wrap-div .prev-modal-wrapping {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  /* transform: translate(-50%); */
  left: 76px;
  height: 40px;
}

.sliderContributor-wrap-div .next-modal-wrapping {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  top: 50%;
  /* transform: translate(-0%); */
  /* right: 300px;
  height: 40px; */
  right: 0px;
  height: 40px;
}
